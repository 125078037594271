// Step-by-step AccountGmail.jsx

import React, {useEffect} from 'react';

const AccountGmail2 = () => {
    useEffect(() => {
        console.log('useEffect');
        setTimeout(function () {
            console.log('Set timeout');
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1><span>Gmail&nbsp;</span><span>(2 of 2)</span></h1>
            <ol start="4">
                <li>There will be a long list of options, but please scroll until you find <strong>Create contacts for auto-complete</strong> - this will be about halfway down the page:<br/>
                    <div className="override"><img className="" alt="Create contacts for auto-complete" src={require("../../../assets/images/gmail-003.png")}/></div>
                </li>
                <li>Change the setting to: <strong>I&rsquo;ll add contacts myself</strong></li>
                <li>Scroll to the very bottom of the page and click <strong>Save Changes</strong><br/>
                    <img alt="Save changes" src={require("../../../assets/images/gmail-004.png")}/>
                </li>
                <li>Leave Gmail open and move to the next section.</li>
            </ol>
        </div>
    );
};

export default AccountGmail2;
