// PcVaioIntune3.jsx

import React, {useEffect} from 'react';

const PcVaioIntune3 = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
            <div className="window-content">
                <h1><span>Windows&nbsp;</span><span>- VAIO - Windows Hello (2 of 7)</span></h1>
                <h3>Setting up Windows Hello PIN</h3>
                <div id="vaio" className="guidance-sub-sub-block">
                    <ol start="2">
                        <li className="override">In the Windows Settings – On the left-hand side, click on <strong>Accounts</strong> and then click on <strong>Sign-in options</strong>
                            <br/>
                            <img alt="Microsoft Security Confirmation" src={require("../../assets/images/vaio-intune-004.png")}/>
                        </li>
                    </ol>
                </div>
            </div>
    );
};

export default PcVaioIntune3;
