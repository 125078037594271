// Step-by-Step Introduction.jsx

import React, {useEffect} from 'react';

// add BYOD wifi password

const Introduction = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <div className="introduction description-text">
                Welcome to HENNGE! You will use this site to set up your work PC and accounts. Get started by pressing <strong>Next</strong>!
            </div>
            <div className="instruction-content">
                <div className="intro-contents">
                    <div className="grid-intro"><strong>Contents and Timetable</strong></div>
                    <div className="contents-grid">
                        <div className="contact-grid-row">
                            <div className="contact-grid-column number">1.</div>
                            <div className="contact-grid-column long">About Security Rules</div>
                            <div className="contact-grid-column short">5 mins</div>
                        </div>
                        <div className="contact-grid-row">
                            <div className="contact-grid-column number">2.</div>
                            <div className="contact-grid-column long">ISMS training</div>
                            <div className="contact-grid-colum short">25 mins</div>
                        </div>
                        <div className="contact-grid-row">
                            <div className="contact-grid-column number">3.</div>
                            <div className="contact-grid-column long">PC Setup</div>
                            <div className="contact-grid-column short">15 mins</div>
                        </div>
                        <div className="contact-grid-row">
                            <div className="contact-grid-column number">4.</div>
                            <div className="contact-grid-column long">Accounts Setup and Service Descriptions</div>
                            <div className="contact-grid-column short">20 mins</div>
                        </div>
                        <div className="contact-grid-row">
                            <div className="contact-grid-column number">5.</div>
                            <div className="contact-grid-column long">Shibuya Office Door Access</div>
                            <div className="contact-grid-column short">10 mins</div>
                        </div>
                    </div>
                </div>
                <div className="intro-and-grid">
                    <div className="grid-intro">
                        You can reach out to an Internal IT member if you
                        have any issues or questions:
                    </div>
                    <div className="contact-grid">
                        <div className="contact-grid-row">
                            <div className="contact-grid-column">Akane Kawatake</div>
                            <div className="contact-grid-column">@akane</div>
                        </div>
                        <div className="contact-grid-row">
                            <div className="contact-grid-column">Eiichi Hosaka</div>
                            <div className="contact-grid-column">@eiichi.hosaka</div>
                        </div>
                        <div className="contact-grid-row">
                            <div className="contact-grid-column">Jennifer Lowry</div>
                            <div className="contact-grid-column">@jennifer</div>
                        </div>
                        <div className="contact-grid-row">
                            <div className="contact-grid-column flex-with-wrap"><span>Channel to ask&nbsp;</span><span>for help</span></div>
                            <div className="contact-grid-column"><strong>#hello-backoffice</strong></div>
                        </div>
                        <div className="contact-grid-row">
                            <div className="contact-grid-column">Group mention</div>
                            <div className="contact-grid-column"><strong>@grp-iit</strong></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="introduction-get-started">
                Are you ready? Click the <strong>Next</strong> button below to get started!
            </div>
        </div>
    );
};
export default Introduction;