// Step-by-step OfficeSecurity.jsx

import React, {useEffect} from 'react';

const OfficeSecurity = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
            <div className="window-content">
                <h1><span>Office Security System&nbsp;</span><span> - Akerun&nbsp;</span></h1>
                <div className="img-with-text header">
                    <div className="img-container">
                        <img alt="Kisi" src={require("../../assets/images/akerun-001.png")}/>
                    </div>
                    <div className="description-text">
                        Akerun is the service we are using to manage access to the Shibuya office.<br/>
                        If you came to Shibuya Office for your first day, you should have already registered with Akerun.<br/>
                        If you need to register, please let IIT know in advance so someone can help you.<br/>
                        It is not possible to register a card by yourself. <br/>
                        For more information about Akerun, please check <a href={"https://docs.google.com/document/d/1VelOVGuonscVI-z62eSVoxaOqgMZYsyzxnn98Q64uSM/edit"}>this document</a>
                    </div>

                </div>
                <div className="guidance-sub-block">
                    To unlock doors with Akerun, an IC card must be registered. It's possible to use any of the following types:
                    <div className="flex">
                        <div className="list-block half-width">
                            <strong>Physical FeliCa / MIFARE DESFire:</strong>
                            <ul>
                                <li>Transportation IC card (SUICA, PASMO, etc)</li>
                                <li>Electronic money (WAON, nanaco, etc)</li>
                                <li>Kisi card, etc.</li>
                            </ul>
                        </div>
                        <div className="list-block">
                            <strong>Mobile wallet cards (iPhone / Apple Watch / Android):</strong>
                            <ul>
                                <li>Mobile SUICA, PASMO, etc.</li>
                                <li>It should be set as an "Express travel card"</li>
                            </ul>
                        </div>
                    </div>
                    </div>
                <div className="margin-buffer">
                    <strong>Important Points to remember:</strong>
                    <ul>
                        <li>You can only register <strong>one card</strong></li>
                        <li>If you lose your card / device, please contact IIT IMMEDIATELY (even on weekends or late at night)</li>
                    </ul>
                </div>
            </div>
    );
};

export default OfficeSecurity;
