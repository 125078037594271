// Step-by-step AccountRakumo.jsx

import React, {useEffect} from 'react';

const AccountRakumo = () => {
    useEffect(() => {
        console.log('useEffect');
        setTimeout(function () {
            console.log('Set timeout');
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1><span>Rakumo Calendar / Workflow&nbsp;</span><span>(1 of 2)</span></h1>
            <img alt="Rakumo" src={require("../../../assets/images/rakumo-001.png")}/>
            <div className="description-text">
                If you are a Partner or Intern, you will only have access to Calendar.<br/><br/>
                Rakumo Calendar and Google Calendar are synced, so any changes you make in one will be reflected in the other. You are free to use whichever works best for you!<br/><br/>
                Rakumo Workflow is an application for managerial decisions using a workflow system. You would most likely use this for handling expense requests.
            </div>
        </div>
    );
};

export default AccountRakumo;
