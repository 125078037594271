// UserContextProvider.jsx

import { Auth, Hub } from 'aws-amplify';
import PropTypes from 'prop-types';
import React, { cloneElement } from 'react';
import Cache from './Cache';
import UserContext from './UserContext';

const reducer = (state, action) => {
    switch (action.type) {
        case 'signIn':
            return {
                ...state,
                henngeAccount: action.payload.henngeAccount,
                username: action.payload.username,
                currPage: action.payload.currPage,
                lastMessage: action.payload.lastMessage,
            };
        case 'signOut':
            return {
                ...state,
                henngeAccount: null,
                username: null,
                currPage: null,
                lastMessage: null,
            };
        default:
            return state;
    }
};

const UserContextProvider = ({ user, ...props }) => {
    const [state, dispatch] = React.useReducer(reducer, user);
    const { children, authState } = props;

    Hub.listen('auth', async (data) => {
        switch (data.payload.event) {
            case 'signIn':
                const loggedIn = {
                    henngeAccount: data.payload.data.username.includes('HENNGE') ? '' : 'true',
                    username: data.payload.data.username.replace('HENNGE_','').split('@')[0],
                    currPage: '',
                    lastMessage: '',
                };

                dispatch({
                    type: 'signIn',
                    payload: {
                        henngeAccount: loggedIn.henngeAccount,
                        username: loggedIn.username,
                        currPage: loggedIn.currPage,
                        lastMessage: loggedIn.lastMessage,
                    },
                });
                Cache.updateItems(loggedIn);

                // Check if SAML, if yes then add signedIn flag to proceed past login page on redirect
                Auth.currentSession().then((data) => {
                    if (
                        data.getIdToken().payload.identities &&
                        data.getIdToken().payload.identities[0].providerName ===
                            'HENNGE'
                    ) {
                        Cache.updateItems({ localAuthState: 'signedIn' });
                        window.location.reload();
                    }
                });

                break;
            case 'signIn_failure':
                console.log('Sign In Failure');
                break;
            case 'signOut':
                dispatch({ type: 'signOut' });
                Cache.removeItems([
                    'henngeAccount',
                    'username',
                    'currPage',
                    'lastMessage',
                    'localAuthState',
                ]);
                break;
            default:
                break;
        }
    });

    return (
        <UserContext.Provider
            value={{
                ...state,
            }}
        >
            {cloneElement(children, { authState })}
        </UserContext.Provider>
    );
};

//

UserContextProvider.propTypes = {
    user: PropTypes.shape(),
    children: PropTypes.shape(),
};

UserContextProvider.defaultProps = {
    user: {},
    children: {},
};

export default UserContextProvider;
