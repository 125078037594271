// Step-by-step AccountFzero.jsx

import React, {useEffect} from 'react';

const AccountFzero = () => {
    useEffect(() => {
        console.log('useEffect');
        setTimeout(function () {
            console.log('Set timeout');
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1><span>Fzero&nbsp;</span><span>(1 of 2)</span></h1>
            <div className="img-with-text">
                <div className="img-container">
                    <img alt="Rakumo" src={require("../../../assets/images/fzero-001.png")}/>
                </div>
                <div className="description-text">
                    Fzero is a mail security system, and is part of our product, HENNGE One. <br/>
                    Fzero filters emails sent from Gmail and performs different actions based on
                    the rule configuration. For example, emails can be temporarily suspended for a particular period or suspended until the user releases them manually.<br/><br/>
                    This system prevents misdirected emails and also emails with wrong content or attachments.
                    Within a 5 minute window, you have the ability to discard or send emails that are in this temporary zone.<br/>
                    The rules are different for different divisions, but the common rules are:
                </div>
            </div>
            <div className="flex-conditional-wrap">
                <div className="list-block half-width">
                    <ul>
                        <li>Emails to an internal domain
                            <ul>
                                <li>Send immediately</li>
                            </ul>
                        </li>
                        <li>Emails to an external domain
                            <ul>
                                <li>Suspend for 15 min</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="list-block">
                    <ul>
                        <li>Emails to an external domain with attachments
                            <ul>
                                <li>Encrypt attachments and suspend until approval</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default AccountFzero;
