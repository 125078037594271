// PcMac3.jsx

import React, {useEffect} from 'react';
import '../StepByStep/StepByStep.css';

const PcMac3 = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
            <div className="window-content">
                <h1>Mac (3 of 3)</h1>
                <div className="guidance-sub-sub-block">
                    <h3>Change Mac Login Password</h3>
                    <ol start="6">
                        <li>
                            In <strong>Old password</strong>, please enter the temporary password
                        </li>
                        <li>
                            In both <strong>New password</strong> and <strong>Verify</strong>, please enter your new password<br/>
                            <img alt="Change Password Menu" src={require("../../assets/images/mac-004.png")}/>
                        </li>
                        <li>You may enter a password hint if you would like.</li>
                        <li>Click <strong>Change Password</strong></li>
                        <li>Then, go to the next step.</li>
                    </ol>
                </div>
            </div>
    );
};

export default PcMac3;
