// Utils.jsx

import request from "request-promise";
import Cache from '../../Cache';
import { guidanceNotifierApiUrl, guidanceNotifierApiKey } from '../../config.json';

const sendUpdateToChannnel = (message, messageSuffix) => {
    const updateUrl ='https://hooks.slack.com/services/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';
    const body = {
        channel: '#iit-isms-notifier',
        username: 'IIT Guidance Notifier',
        'icon_emoji': ':computer:',
        'attachments': [
            {
                'text': message
            }
        ]
    };
    const options = {
        method: 'POST',
        uri: updateUrl,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(body)
    };
    request(options)
        .then(response => {
            Cache.updateItems({
                lastMessage: messageSuffix
            });
        })
        .catch(err => console.log(err));
};

const sendUpdateToChannel = (username, message, messageSuffix) => {
    console.log('in new function');
    const body = {
        username,
        message,
    };

    const options = {
        method: 'POST',
        uri: guidanceNotifierApiUrl,
        headers: {
            'Accept': 'application/json',
            'x-api-key': guidanceNotifierApiKey
        },
        body: JSON.stringify(body),
    };
    request(options)
        .then(response => {
            Cache.updateItems({
                lastMessage: messageSuffix
            });
        })
        .catch(err => console.log(err));
};

const handleOnClick = (e, dispatch, newPage, message) => {
    e.preventDefault();
    if(newPage) {
        Cache.updateItems({currPage: newPage});
        dispatch({
            type: 'movePage',
            payload: {
                currPage: newPage,
                lastMessage: message,
            },
        });
    }
};

export {
    sendUpdateToChannnel,
    sendUpdateToChannel,
    handleOnClick,
};