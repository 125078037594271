// Step-by-step AccountSansan.jsx

import React, {useEffect} from 'react';

const AccountSansan = () => {
    useEffect(() => {
        console.log('useEffect');
        setTimeout(function () {
            console.log('Set timeout');
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1>Sansan</h1>
            <div className="img-with-text">
                <div className="img-container">
                    <img alt="Sansan" src={require("../../../assets/images/sansan-001.png")}/>
                </div>
                <div className="description-text">
                    If you are an Intern, you will most likely not have access to Sansan.<br/>
                    Sansan is a cloud-based contact management tool for corporations, centered around business cards.
                    <ul>
                        <li>Allows you to update business card information and share it with your colleagues</li>
                        <li>Has functions that can help you increase your sales</li>
                    </ul>
                </div>
            </div>

            <div className="description-text">
                There are scanners on both the 4th and 5th floors of the Shibuya office. You can also install the Sansan application on your smartphone.
                All the business cards you received from customers have to be scanned and be disposed by shredding. You must not keep the physical business cards.
            </div>
            <ol>
                <li>Click on the <strong>Sansan icon</strong> to log in</li>
                <li>Choose <strong>Later</strong> when prompted to set up on your phone</li>
                <li>Confirm login is successful</li>
                <li>Please return to the HENNGE One portal.</li>
            </ol>
        </div>
    );
};

export default AccountSansan;
