// PageIndex.jsx

const PageIndex = {
    'introduction': {
        nextPage: 'how-to-use',
        prevPage: '',
        progressPercent: 0,
        messageSuffix: ' has logged in to Welcome Guidance',
        label: 'Introduction',
        tier: '1',
        parent: '',
    },
    'how-to-use': {
        nextPage: 'security-1',
        prevPage: 'introduction',
        progressPercent: 0,
        messageSuffix: ' is reading How to Use This Guide',
        label: 'How to Use',
        tier: '1',
        parent: '',
    },
    'security-1': {
        nextPage: 'security-2',
        prevPage: 'how-to-use',
        progressPercent: 0,
        messageSuffix: ' started reading Security Rules',
        label: 'Security Rules',
        tier: '1',
        parent: '',
    },
    'security-2': {
        nextPage: 'security-3',
        prevPage: 'security-1',
        progressPercent: 0,
        messageSuffix: '',
        label: 'Security Rules 2',
        tier: '2',
        parent: 'security-1',
    },
    'security-3': {
        nextPage: 'security-4',
        prevPage: 'security-2',
        progressPercent: 0,
        messageSuffix: '',
        label: 'Security Rules 3',
        tier: '2',
        parent: 'security-1',
    },
    'security-4': {
        nextPage: 'isms',
        prevPage: 'security-3',
        progressPercent: 0,
        messageSuffix: '',
        label: 'Security Rules 4',
        tier: '2',
        parent: 'security-1',
    },
    'isms': {
        nextPage: 'isms-2',
        prevPage: 'security-4',
        progressPercent: 0,
        messageSuffix: ' started reading ISMS instructions',
        label: 'ISMS Overview',
        tier: '1',
        parent: '',
    },
    'isms-2': {
        nextPage: '',
        prevPage: 'isms',
        progressPercent: 0,
        messageSuffix: '',
        label: 'ISMS Overview 2',
        tier: '2',
        parent: 'isms',
    },
    'password-policy': {
        nextPage: 'pc-setup',
        prevPage: 'isms-2',
        progressPercent: 0,
        messageSuffix: ' is selecting a PC Setup option.',
        label: 'PC Setup',
        tier: '1',
        parent: '',
    },
    'pc-setup': {
        nextPage: '',
        prevPage: 'password-policy',
        progressPercent: 0,
        messageSuffix: ' is selecting a PC Setup option.',
        label: 'PC Setup',
        tier: '1',
        parent: '',
    },
    'pc-chromebook': {
        nextPage: 'pc-chromebook2',
        prevPage: 'pc-setup',
        progressPercent: 0,
        messageSuffix: ' selected Chromebook setup.',
        label: 'Chromebook Setup',
        tier: '2',
        parent: 'pc-setup',
    },
    'pc-chromebook2': {
        nextPage: 'account-setup',
        prevPage: 'pc-setup',
        progressPercent: 0,
        messageSuffix: '',
        label: 'Chromebook Setup 2',
        tier: '2',
        parent: 'pc-setup',
    },
    'pc-vaio': {
        nextPage: 'pc-vaio-2',
        prevPage: 'pc-setup',
        progressPercent: 0,
        messageSuffix: ' selected Windows - VAIO setup.',
        label: 'Vaio Setup',
        tier: '2',
        parent: 'pc-setup',
    },
    'pc-vaio-2': {
        nextPage: 'pc-vaio-3',
        prevPage: 'pc-setup',
        progressPercent: 0,
        messageSuffix: ' selected Windows - VAIO setup.',
        label: 'Vaio Setup 2',
        tier: '3',
        parent: 'pc-vaio',
    },
    'pc-vaio-3': {
        nextPage: 'pc-vaio-4',
        prevPage: 'pc-vaio-2',
        progressPercent: 0,
        messageSuffix: ' is setting up their Windows Hello PIN',
        label: 'Vaio Setup 3',
        tier: '3',
        parent: 'pc-vaio',
    },
    'pc-vaio-4': {
        nextPage: 'pc-vaio-5',
        prevPage: 'pc-vaio-3',
        progressPercent: 0,
        messageSuffix: '',
        label: 'Vaio Setup 4',
        tier: '3',
        parent: 'pc-vaio',
    },
    'pc-vaio-5': {
        nextPage: 'pc-vaio-6',
        prevPage: 'pc-vaio-4',
        progressPercent: 0,
        messageSuffix: '',
        label: 'Vaio Setup 5',
        tier: '3',
        parent: 'pc-vaio',
    },
    'pc-vaio-6': {
        nextPage: 'pc-vaio-7',
        prevPage: 'pc-vaio-5',
        progressPercent: 0,
        messageSuffix: ' is setting up their fingerprint',
        label: 'Vaio Setup 6',
        tier: '3',
        parent: 'pc-vaio',
    },
    'pc-vaio-7': {
        nextPage: 'pc-vaio-8',
        prevPage: 'pc-vaio-6',
        progressPercent: 0,
        messageSuffix: '',
        label: 'Vaio Setup 7',
        tier: '3',
        parent: 'pc-vaio',
    },
    'pc-vaio-8': {
        nextPage: 'account-setup',
        prevPage: 'pc-vaio-7',
        progressPercent: 0,
        messageSuffix: '',
        label: 'Vaio Setup 8',
        tier: '3',
        parent: 'pc-vaio',
    },
    'windows-login': {
        nextPage: 'windows-login-2',
        prevPage: 'pc-setup',
        progressPercent: 0,
        messageSuffix: ' started reading the Windows Login setup instructions.',
        label: 'Windows Login',
        tier: '2',
        parent: 'pc-setup',
    },
    'windows-login-2': {
        nextPage: 'windows-login-3',
        prevPage: 'windows-login',
        progressPercent: 0,
        messageSuffix: '',
        label: 'Windows Login 2',
        tier: '3',
        parent: 'windows-login',
    },
    'windows-login-3': {
        nextPage: 'account-setup',
        prevPage: 'windows-login-2',
        progressPercent: 0,
        messageSuffix: '',
        label: 'Windows Login 3',
        tier: '3',
        parent: 'windows-login',
    },
    'pc-mac': {
        nextPage: 'pc-mac-2',
        prevPage: 'pc-setup',
        progressPercent: 0,
        messageSuffix: ' selected Mac setup.',
        label: 'Mac Setup',
        tier: '2',
        parent: 'pc-setup',
    },
    'pc-mac-2': {
        nextPage: 'mac-jamf',
        prevPage: 'pc-mac',
        progressPercent: 0,
        messageSuffix: '',
        label: 'Mac Setup 2',
        tier: '3',
        parent: 'pc-mac',
    },
    'mac-jamf': {
        nextPage: 'mac-icloud',
        prevPage: 'pc-setup',
        progressPercent: 0,
        messageSuffix: '',
        label: 'Mac Jamf',
        tier: '3',
        parent: 'mac-icloud',
    },
    'mac-icloud': {
        nextPage: 'account-setup',
        prevPage: 'mac-jamf',
        progressPercent: 0,
        messageSuffix: '',
        label: 'Mac iCloud',
        tier: '3',
        parent: 'pc-mac',
    },
    'account-setup': {
        nextPage: 'account-slack',
        prevPage: 'pc-setup',
        progressPercent: 0,
        messageSuffix: ' started reading Account Setup',
        label: 'Account Setup Overview',
        tier: '1',
        parent: '',
    },
    'account-slack': {
        nextPage: 'account-slack-2',
        prevPage: 'account-setup',
        progressPercent: 0,
        messageSuffix: ' started reading Slack setup',
        label: 'Slack Setup',
        tier: '2',
        parent: 'account-setup',
    },
    'account-slack-2': {
        nextPage: 'account-slack-3',
        prevPage: 'account-slack',
        progressPercent: 0,
        messageSuffix: '',
        label: 'Slack Setup 2',
        tier: '3',
        parent: 'account-slack',
    },
    'account-slack-3': {
        nextPage: 'account-slack-4',
        prevPage: 'account-slack-2',
        progressPercent: 0,
        messageSuffix: '',
        label: 'Slack Setup 3',
        tier: '3',
        parent: 'account-slack',
    },
    'account-slack-4': {
        nextPage: 'account-slack-5',
        prevPage: 'account-slack-3',
        progressPercent: 0,
        messageSuffix: '',
        label: 'Slack Setup 4',
        tier: '3',
        parent: 'account-slack',
    },
    'account-slack-5': {
        nextPage: 'account-slack-6',
        prevPage: 'account-slack-4',
        progressPercent: 0,
        messageSuffix: '',
        label: 'Slack Setup 5',
        tier: '3',
        parent: 'account-slack',
    },
    'account-slack-6': {
        nextPage: 'account-chrome-sync',
        prevPage: 'account-slack-5',
        progressPercent: 0,
        messageSuffix: '',
        label: 'Slack Setup 6',
        tier: '3',
        parent: 'account-slack',
    },
    'account-chrome-sync': {
        nextPage: 'account-gmail',
        prevPage: 'account-slack-6',
        progressPercent: 0,
        messageSuffix: ' started reading Google Sync',
        label: 'Chrome Sync',
        tier: '2',
        parent: 'account-setup',
    },
    'account-gmail': {
        nextPage: 'account-gmail-2',
        prevPage: 'account-chrome-sync',
        progressPercent: 0,
        messageSuffix: ' started reading Gmail setup',
        label: 'Gmail Setup',
        tier: '2',
        parent: 'account-setup',
    },
    'account-gmail-2': {
        nextPage: 'account-gchat',
        prevPage: 'account-gmail',
        progressPercent: 0,
        messageSuffix: '',
        label: 'Gmail Setup 2',
        tier: '3',
        parent: 'account-gmail',
    },
    'account-gchat': {
        nextPage: 'account-gchat-2',
        prevPage: 'account-gmail-2',
        progressPercent: 0,
        messageSuffix: ' started reading Gchat setup',
        label: 'Gchat Setup',
        tier: '3',
        parent: 'account-setup',
    },
    'account-gchat-2': {
        nextPage: 'account-gchat-3',
        prevPage: 'account-gchat',
        progressPercent: 0,
        messageSuffix: '',
        label: 'Gchat Setup 2',
        tier: '3',
        parent: 'account-gchat',
    },
    'account-gchat-3': {
        nextPage: 'account-gdrive',
        prevPage: 'account-gchat-2',
        progressPercent: 0,
        messageSuffix: '',
        label: 'Gchat Setup 3',
        tier: '3',
        parent: 'account-gchat',
    },
    'account-gdrive': {
        nextPage: 'account-keeper',
        prevPage: 'account-gmail-2',
        progressPercent: 0,
        messageSuffix: ' started reading Google Drive setup',
        label: 'Google Drive Setup',
        tier: '2',
        parent: 'account-setup',
    },
    'account-keeper': {
        nextPage: 'account-kickflow',
        prevPage: 'account-gdrive',
        progressPercent: 0,
        messageSuffix: ' started reading Keeper setup',
        label: 'Keeper Setup',
        tier: '2',
        parent: 'account-setup',
    },
    'account-kickflow': {
        nextPage: 'account-fzero',
        prevPage: 'account-keeper',
        progressPercent: 0,
        messageSuffix: ' started reading Kickflow setup',
        label: 'Kickflow Setup',
        tier: '2',
        parent: 'account-setup',
    },
    'account-fzero': {
        nextPage: 'account-fzero-2',
        prevPage: 'account-kickflow',
        progressPercent: 0,
        messageSuffix: ' started reading Fzero setup',
        label: 'Fzero Setup',
        tier: '2',
        parent: 'account-setup',
    },
    'account-fzero-2': {
        nextPage: 'account-secure-transfer',
        prevPage: 'account-fzero',
        progressPercent: 0,
        messageSuffix: '',
        label: 'Fzero Setup 2',
        tier: '3',
        parent: 'account-fzero',
    },
    'account-secure-transfer': {
        nextPage: 'account-teamspirit',
        prevPage: 'account-fzero-2',
        progressPercent: 0,
        messageSuffix: ' started reading Hennge Secure Transfer setup',
        label: 'Hennge Secure Transfer Setup',
        tier: '2',
        parent: 'account-setup',
    },
    'account-teamspirit': {
        nextPage: 'account-office',
        prevPage: 'account-secure-transfer',
        progressPercent: 0,
        messageSuffix: ' started reading Teamspirit setup',
        label: 'Teamspirit Setup',
        tier: '2',
        parent: 'account-setup',
    },
    'account-office': {
        nextPage: 'account-sansan',
        prevPage: 'account-teamspirit',
        progressPercent: 0,
        messageSuffix: ' started reading MS Office setup',
        label: 'MS Office Setup',
        tier: '2',
        parent: 'account-setup',
    },
    'account-sansan': {
        nextPage: 'account-kaonavi',
        prevPage: 'account-office',
        progressPercent: 0,
        messageSuffix: ' started reading Sansan setup',
        label: 'Sansan Setup',
        tier: '2',
        parent: 'account-setup',
    },
    'account-kaonavi': {
        nextPage: 'account-pink-pony',
        prevPage: 'account-sansan',
        progressPercent: 0,
        messageSuffix: ' started reading Kaonavi setup',
        label: 'Kaonavi Setup',
        tier: '2',
        parent: 'account-setup',
    },
    'account-pink-pony': {
        nextPage: 'account-hennge-docs',
        prevPage: 'account-kaonavi',
        progressPercent: 0,
        messageSuffix: ' started reading Pink Pony setup',
        label: 'Pink Pony Setup',
        tier: '2',
        parent: 'account-setup',
    },
    'account-hennge-docs': {
        nextPage: 'office-security',
        prevPage: 'account-kaonavi',
        progressPercent: 0,
        messageSuffix: ' started reading Hennge Documents setup',
        label: 'Hennge Documents Setup',
        tier: '2',
        parent: 'account-setup',
    },
    'office-security': {
        nextPage: 'guidance-complete',
        prevPage: 'account-hennge-docs',
        progressPercent: 0,
        messageSuffix: ' started reading Office Security',
        label: 'Office Security',
        tier: '1',
        parent: '',
    },
    'guidance-complete': {
        nextPage: '',
        prevPage: 'office-security',
        progressPercent: 0,
        messageSuffix: ' has completed Welcome Guidance! :tada:',
        label: '',
        tier: '',
        parent: '',
    }
};

const setProgressPercent = () => {
    let percent = 0;
    const pages = Object.keys(PageIndex);

    let singlePagePercent = 100 / pages.length;

    for(let i = 0; i < pages.length; i++) {
        if (i + 1 === pages.length) {
            PageIndex[pages[i]].progressPercent = 100;
        } else {
            percent = percent + singlePagePercent;
            PageIndex[pages[i]].progressPercent = Math.floor(percent);
        }
    }
};

setProgressPercent();

export default PageIndex;
