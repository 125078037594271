// Step-by-step AccountSlack.jsx

import React, {useEffect} from 'react';

const AccountSlack = () => {
    useEffect(() => {
        console.log('useEffect');
        setTimeout(function () {
            console.log('Set timeout');
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1><span>Slack&nbsp;</span><span>(1 of 6)</span></h1>
            <div className="img-with-text header">
                <div className="img-container">
                    <img alt="Slack" src={require("../../../assets/images/slack-001.png")}/>
                </div>
                <div className="description-text">
                    Slack is our primary method of internal communication.
                    You should have Slack open in a browser or use the application while you are at work so you will see your notifications timely. <br/><br/>
                    <strong>IMPORTANT:</strong> As a general rule, please have conversations in public channels whenever possible!
                </div>
            </div>
            <div className="margin-buffer">
                By having conversations in areas that are visible to all, we can increase transparency and gather opinons from many different valuable sources!
                Also, someone else may have the same question as you, so by asking publicly many people can get the answer at the same time, which is more efficient.<br/><br/>
                A <strong>public channel</strong> is any channel which is displayed with the hash symbol (<strong>#</strong>) rather than the lock symbol (🔒), and which is not a direct message.<br/><br/>
                For more details, check the <strong>Slack User Manual</strong> in HENNGE Documents, under the Internal IT Related section.
            </div>
            <div className="description-text no-buffer">
                If you're not sure where to post something, <strong>#hello-backoffice</strong> or <strong>#random</strong> are good places to try asking your question!
            </div>
        </div>
    );
};

export default AccountSlack;
