// Step-by-step Isms2.jsx

import React, {useContext, useEffect} from 'react';
import Cache from "../../Cache";
import StepContext from "../StepByStep/StepContext";

const handleOnClick = (e, dispatch, newPage) => {
    e.preventDefault();
    console.log('handleOnClick newPage: ', newPage);
    if(newPage) {
        Cache.updateItems({currPage: newPage});
        dispatch({
            type: 'movePage',
            payload: {
                currPage: newPage,
            },
        });
    }
};

const Isms2 = () => {
    const [, dispatch] = useContext(StepContext);
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1><span>ISMS Training&nbsp;</span><span>(2 of 2)</span></h1>
            <ol start="3">
                <li><strong>Please read the manual carefully.</strong> This process will take you around 20 minutes.</li>
                <li>Once you have completed reading the training material, please return to the
                    ISMS page to take the <strong>Confirmation Test</strong>.
                </li>
                <li>When you have completed the test with <strong>a score of 75 or higher</strong>, please continue to the next section by clicking the button below.</li>
            </ol>
            <div className="button-choice-container">
                <a href="/#" onClick={e => {
                    handleOnClick(e, dispatch, 'password-policy');
                }}><div className="button-choice complete">
                    I have completed reading the ISMS manual and have passed the Confirmation Test with a score of 75 or more
                </div></a>
            </div>
            <div className="description-text margin-buffer">
               <strong>Do not skip this step</strong> unless you have been specifically instructed to.
                If you skip without permission, you will be asked to go back and take this training before you may move on.
            </div>
        </div>
    );
};
export default Isms2;
