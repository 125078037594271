// Step-by-step AccessControl3.jsx

import React, { useEffect } from 'react';

const AccessControl3 = () => {
    // const [, dispatch] = useContext(StepContext);
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
            <div className="window-content">
                <h1><span>HENNGE Access&nbsp;</span><span>Control (SSO)&nbsp;Setup </span><span>(3 of 6)</span></h1>
                <ol start="10">
                    <li>Choose <strong>OTP (One-Time Password) settings</strong></li>
                    <li>Select <strong>Configure OTP</strong><br/>
                        <img alt="Configure OTP" src={require("../../assets/images/hac-002.png")}/>
                    </li>
                    <li><div className="img-with-text">Select your smartphone type <img alt="Select Type" src={require("../../assets/images/hac-003.png")}/></div>
                        <ul>
                            <li>If it is not possible for you to install HENNGE Lock on your phone,
                                you may choose <strong>Other</strong> and set up OTP via email however
                                we <strong>strongly recommend</strong> you use HENNGE Lock if possible.
                            </li>
                        </ul>
                    </li>
                </ol>
            </div>
    );
};

export default AccessControl3;
