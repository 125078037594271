// Chromebook.jsx

import React, {useEffect} from 'react';
import PasswordRules from "../PasswordRules";

const Chromebook2 = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1>Chromebook (2 of 2)</h1>
            <div className="guidance-sub-sub-block">
                <ol start="5">
                    <li>On the <strong>Enter TOTP token</strong> screen, use the temporary password and click <strong>LOG IN</strong><br/>
                        <img alt="Chrome login screen" src={require("../../assets/images/chromebook-003.jpg")}/>
                    </li>
                    <li>You will be brought to the Change Password screen, please input the temporary password as
                        the “current password”.
                        <img alt="Chrome login screen" src={require("../../assets/images/chromebook-004.jpg")}/>
                    </li>
                    <li>For your new password, make sure it follows these rules:
                        <PasswordRules/>
                    </li>
                </ol>
            </div>
        </div>
    );
};

export default Chromebook2;
