// Step-by-step GoogleChat.jsx

import React, {useEffect} from 'react';

const GoogleChat2 = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
            <div className="window-content">
                <h1><span>Google Chat&nbsp;</span><span>(2 of 3)</span></h1>
                <ol start="2">
                    <li>
                        <span>Click on <strong>New chat</strong> and then <strong>Browse spaces</strong></span><br/>
                        <img alt="Browse Spaces" src={require("../../assets/images/google-chat-002.png")}/>
                    </li>
                    <li>
                        <span>Search for <strong>Official</strong> and click <strong>Join</strong> to join the space.</span><br/>
                        <img alt="Search for official" src={require("../../assets/images/google-chat-003.png")}/>
                        <img alt="Join button" src={require("../../assets/images/google-chat-004.png")}/>
                    </li>
                </ol>
            </div>
    );
};

export default GoogleChat2;
