// MaciCloud.jsx

import React, {useEffect} from 'react';
import '../StepByStep/StepByStep.css';

const MaciCloud = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
            <div className="window-content">
                <h1>Mac - About iCloud</h1>
                <div className="guidance-sub-sub-block">
                    <h3>iCloud is Disabled on Jamf-managed Devices</h3>
                    <div className="description-text">
                        If you're familiar with Macs, you may be used to using iCloud to sync documents, notes, or your keychain.
                        Unfortunately, because we cannot restrict the types of Apple ID accounts that can sync information,
                        <strong> iCloud syncing is disabled</strong>, even for a HENNGE Apple ID.
                        <br/><br/>
                        Because of this, please take care to store your documents or notes in other cloud locations,
                        such as <strong>Google Documents</strong> or <strong>Github</strong>.
                        <br/><br/>
                        Remember, it is always important to <strong>back up any important work <em>frequently </em></strong>
                        to prevent any interruption to your work if your laptop suddenly has a problem.
                        <br/><br/>
                        Let's move to the next step!
                    </div>
                </div>
            </div>
    );
};

export default MaciCloud;
