// Step-by-step KisiApp.jsx

import React, {useEffect} from 'react';

const KisiApp = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1><span>Kisi Application&nbsp;</span><span>(1 of 5)</span></h1>
            <ol>
                <li>Search for <strong>Kisi</strong> on the App Store or Google Play Store: <br/>
                    <img alt="Kisi on App Store" src={require("../../assets/images/kisi-app.png")}/>
                </li>
                <li>Download and install the app</li>
            </ol>
        </div>
    );
};

export default KisiApp;
