// PcMac3.jsx

import React, {useEffect} from 'react';
import '../StepByStep/StepByStep.css';

const MacJamf = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
            <div className="window-content">
                <h1>Mac - Jamf - Self Service</h1>
                <div className="guidance-sub-sub-block">
                    <h3>Self Service </h3>
                    <div className="img-with-text">
                        <div>
                            <div className="description-text">
                                Self Service app is an application where you can execute commands
                                or install applications that may be useful for your work.
                                This app is provided by an MDM service called "Jamf" and is installed
                                on Jamf-enrolled Mac computers. You can find the app from the launchpad.
                            </div>
                            <h4>Examples of apps you can install through Self Service:</h4>
                            <div className="flex-with-wrap">
                                <ol>
                                    <li>Slack</li>
                                    <li>Firefox</li>
                                    <li>Dialpad</li>
                                    <li>Zoom</li>
                                </ol>
                                <ol start="5">
                                    <li>Unarchiver</li>
                                    <li>Microsoft Remote Desktop</li>
                                    <li>Visual Studio Code</li>
                                </ol>
                            </div>

                        </div>
                        <img alt="Self Service" src={require("../../assets/images/mac-jamf.png")}/>
                    </div>
                    <div>
                        You can also install the drivers for the Multi Printer in Shibuya office and re-enable FileVault through Self Service.
                        <br/><br/>
                        <strong>You don't need to install anything right now</strong>, so let's just move to the next step!
                    </div>
                </div>
            </div>
    );
};

export default MacJamf;
