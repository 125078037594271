// Step-by-step KisiApp2.jsx

import React, {useEffect} from 'react';

const KisiApp2 = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1><span>Kisi Application&nbsp;</span><span>(2 of 5)</span></h1>
            <ol start="3">
                <li>Tap on <strong>Manual sign in</strong> on the bottom of the screen<br/>
                    <img alt="SSO/ORGANIZATION USER" src={require("../../assets/images/kisi-002-new.png")}/>
                </li>
                <li>
                    For Organization Domain, enter <strong>hennge</strong><br/>
                    <img alt="Enter the Organization Domain" src={require("../../assets/images/kisi-003.png")}/>
                </li>
                <li>Tap <strong>Sign In</strong></li>
            </ol>
        </div>
    );
};
export default KisiApp2;
