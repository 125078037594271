// PcDell2.jsx

import React, {useEffect} from 'react';
import '../StepByStep/StepByStep.css';

const PcDell2 = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1><span>Windows&nbsp;</span><span>- DELL (2 of 2)</span></h1>
            <h3>Change BIOS and HDD passwords</h3>
            <div id="dell" className="guidance-sub-sub-block">
                <ol start="6">
                    <li>
                        <div className="list-inline-img flex-with-wrap">
                            <span>Scroll down and find the <strong>System Password</strong> section.</span>
                            <img alt="System Password" src={require("../../assets/images/dell-002.png")}/>
                        </div>
                    </li>
                    <li>Input the temporary password in the <strong>Enter the old password</strong></li>
                    <li>Create a new password and enter it in <strong>Enter the new password</strong></li>
                    <li>Click <strong>APPLY CHANGES</strong> button</li>
                    <li>Click <strong>EXIT</strong> button</li>
                    <li>You will be asked to enter the BIOS password.</li>
                    <li>Input the password you set in the steps above</li>
                    <li>The Windows login menu will be displayed. Please go to the next section.</li>
                </ol>
            </div>
        </div>
    );
};

export default PcDell2;
