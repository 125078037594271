// Step-by-step AccessControl4.jsx

import React, { useEffect } from 'react';

const AccessControl4 = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
            <div className="window-content">
                <h1><span>HENNGE Access&nbsp;</span><span>Control (SSO)&nbsp;Setup </span><span>(4 of 6)</span></h1>
                <ol start="13">
                    <li>A QR code will appear - scan it to enter the app store and download HENNGE Lock<br/>
                        <img alt="Download QR Code" src={require("../../assets/images/hac-004.png")}/>
                    </li>
                    <li>Download and install the app</li>
                    <li><div className="img-with-text">In Access Control, click Next, and follow the instructions on the page to grant
                        permissions for the app to work correctly
                        <img alt="Grant Permission Instructions" src={require("../../assets/images/hac-005.png")}/>
                    </div>
                    </li>
                </ol>
            </div>
    );
};

export default AccessControl4;
