// Step-by-step PasswordPolicy.jsx

import React, {useEffect} from 'react';
import '../StepByStep/StepByStep.css';
import PasswordRules from "../PasswordRules";

const PasswordPolicy = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
            <div className="window-content">
                <h1>Password Policy</h1>
                <div className="description-text margin-buffer">
                    When setting passwords, please keep the following rules in mind:
                </div>
                <div className="guidance-sub-block">
                    <ul>
                        <li>Keep all passwords to yourself and do not share them with anyone.</li>
                        <li>You will be required to set a password for several services, but please avoid using the same password.</li>
                        <PasswordRules/>
                    </ul>
                </div>
            </div>
    );
};

export default PasswordPolicy;
