// Step-by-step AccessControl5.jsx

import React, { useEffect } from 'react';

const AccessControl5 = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
            <div className="window-content">
                <h1><span>HENNGE Access&nbsp;</span><span>Control (SSO)&nbsp;Setup </span><span>(5 of 6)</span></h1>
                <ol start="16">
                    <li>Click Next</li>
                    <li>Scan the QR code using the app<br/>
                        <ul><li>Note: The real QR code will not be missing the center</li></ul>
                        <img alt="Sample QR Code" src={require("../../assets/images/hac-006.png")}/>
                    </li>
                </ol>
            </div>
    );
};

export default AccessControl5;
