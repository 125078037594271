// Step-by-step KisiApp4.jsx

import React, {useEffect} from 'react';

const KisiApp4 = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
            <div className="window-content">
                <h1><span>Kisi Application&nbsp;</span><span>(4 of 5)</span></h1>
                <ol start="8">
                    <li>You will probably also be asked to <strong>approve the login using HENNGE Lock / OTP</strong>.</li>
                    <li>
                        <div className="img-with-text">
                            <span>A welcome screen will appear. Tap the <strong>blue key icon (Main Entrance)</strong> in the center of the screen</span><br/>
                            <img alt="Kisi Welcome Screen" src={require("../../assets/images/kisi-006.png")}/>
                        </div>
                    </li>
                    <li>
                        <div className="img-with-text">
                        <span>Make sure to <strong>give all the required permissions (notifications, location services, bluetooth)</strong> for the app to work properly.</span>
                            <div>
                                <img alt="Kisi Nofitications" src={require("../../assets/images/kisi-007.png")}/>
                                <img alt="Kisi Location Services" src={require("../../assets/images/kisi-008.png")}/>
                                <img alt="Kisi Bluetooth" src={require("../../assets/images/kisi-009.png")}/>
                            </div>
                        </div>
                    </li>
                </ol>
            </div>
    );
};
export default KisiApp4;
