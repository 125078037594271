// Navigation.jsx

import React, { useContext } from 'react';
import StepContext from "./StepContext";
import PageIndex from "./PageIndex";
import { handleOnClick, sendUpdateToChannel } from "./Utils";
import './StepByStep';
import UserContext from "../../UserContext";

const Navigation = () => {
    const [state, dispatch] = useContext(StepContext);
    const { username, henngeAccount } = useContext(UserContext);
    const { currPage, lastMessage } = state;
    console.log(PageIndex[currPage]);
    let pageKey = currPage;
    if (PageIndex[pageKey] === undefined) {
        pageKey = 'introduction';
    }
    const { nextPage, prevPage, progressPercent, messageSuffix } = PageIndex[pageKey];
    const prevDisabled = prevPage ? '' : 'disabled';
    const nextDisabled = nextPage ? '' : 'disabled';
    const nextHidden = nextDisabled === 'disabled' ? 'hidden' : '';
    const prevHidden = prevDisabled === 'disabled' ? 'hidden' : '';

    if(lastMessage !== messageSuffix && messageSuffix && !henngeAccount) {
        console.log(henngeAccount);
        if (lastMessage === ' is selecting a PC Setup option.') {
            //sendUpdateToChannnel(`${username} chose the Partner PC setup option.`, messageSuffix);
            sendUpdateToChannel(username,`${username} chose the Partner PC setup option.`, messageSuffix);
        }
        // sendUpdateToChannnel(`${username}${messageSuffix}`, messageSuffix);
        sendUpdateToChannel(username,`${username}${messageSuffix}`, messageSuffix);
    }

    return (
        <div className="navigation">
            <div className="button-container">
                <div className={`button-arrow left ${prevHidden}`}></div>
                <a href={`#/${prevPage}`} onClick={e => {
                    handleOnClick(e, dispatch, prevPage, messageSuffix);
                }}>
                    <div className={`back button ${prevHidden}`}><div className="back buttonText">Back</div></div>
                </a>
            </div>
            <div className="progress-bar-container">
                <div className="progress-bar" style={{
                    width: `${progressPercent}%`
                }}>
                </div>
                <div className="progress-percent"><span>{progressPercent}%</span></div>
            </div>
            <div className="button-container">
                <a href={`#/${nextPage}`} onClick={e => {
                    handleOnClick(e, dispatch, nextPage, messageSuffix);
                }}>
                    <div className={`next button ${nextHidden}`}><div className="next buttonText">Next</div></div>
                </a>
                <div className={`button-arrow right ${nextHidden}`}></div>
            </div>
        </div>
    );
};

export default Navigation;
