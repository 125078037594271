// PasswordRules.jsx

import React from 'react';

const PasswordRules = () => {
    return (
            <ul>
                <li>Passwords must have at least 8 characters and must include at least 3 of the following:</li>
                <div className="flex-with-wrap">
                    <div className="list-block">
                        <ul>
                            <li>Upper case</li>
                            <li>Lower case</li>
                        </ul>
                    </div>
                    <div className="list-block">
                        <ul>
                            <li>Numbers</li>
                            <li>Symbols</li>
                        </ul>
                    </div>
                    <div className="list-block">
                        <div className="example-text">
                            Examples: Apl5Kewr9 / J%ms#fIed <br/>(Upper case, lower case, symbols are used)
                        </div>
                    </div>
                </div>
            </ul>
    );
};

export default PasswordRules;