// Step-by-step SecurityRules.jsx

import React, {useEffect} from 'react';
import '../StepByStep/StepByStep.css';

const SecurityRules3 = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1><span>Security Rules&nbsp;</span><span>(3 of 4)</span></h1>
            <div className="guidance-sub-block">
                <h2>Regarding App Installation</h2>
                <div className="guidance-sub-sub-block">
                    <h3>For Mac / iOS Users</h3>
                    <ul>
                        <li>Anything that requires the use of an Apple ID should use an Apple ID created with your hennge.com email address</li>
                        <li>Personal Apple IDs should never be used on company provided devices</li>
                        <li>Do not sync company information to iCloud - if you determine this has happened by accident, please report it through the ISMS incident process</li>
                    </ul>
                </div>
                <div className="guidance-sub-sub-block">
                    <h3>All Users</h3>
                    <ul>
                        <li>If there is paid software you need for work - even if you already personally own a license - please apply to purchase a license via the company</li>
                        <li>If you plan to use open source or free software for your work, please make sure to obtain it via trustworthy sources and research it thoroughly before installation</li>
                        <li>If the software is related to a cloud service, that service must be reviewed before use, regardless if the service is free or paid.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default SecurityRules3;
