// PcVaio.jsx

import React, {useEffect} from 'react';
import '../StepByStep/StepByStep.css';

const WindowsLogin = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1>Change Windows Password (1 of 3)</h1>
            <div id="windows-login" className="guidance-sub-sub-block">
                <ol>
                    <li>On the Windows login screen, enter the temporary password provided to you by the person
                        in charge of this training. </li>
                    <li>
                        Click Windows menu button and select <strong> &ldquo;Settings/設定&rdquo; </strong> menu.<br/>
                        <img alt="Start Menu" src={require("../../assets/images/windows-001.jpg")}/>
                    </li>
                    <li>
                        Choose <strong> &ldquo;Accounts/アカウント&rdquo; </strong><br/>
                        <img alt="Accounts" src={require("../../assets/images/windows-002.png")}/>
                    </li>
                </ol>
            </div>
        </div>
    );
};

export default WindowsLogin;
