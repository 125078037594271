// PcMac.jsx

import React, {useEffect} from 'react';

const PcMac = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1>Mac (1 of 2)</h1>
            <div className="guidance-sub-sub-block">
                <h3>Change Mac Login Password</h3>
                <ol>
                    <li>Click on the apple icon in the top left-hand corner<br/>
                        <img alt="Apple Menu" src={require("../../assets/images/mac-001.png")}/>
                    </li>
                    <li>Select <strong>System Settings</strong></li>
                    <li>In the dialog box, scroll down and select <strong>Users &amp; Groups</strong> <br/>
                        <img alt="Users and Groups" src={require("../../assets/images/mac-002.png")}/>
                    </li>
                    <li>Next to your name, click on the <img alt="info icon" src={require("../../assets/images/mac-info-icon.png")} /></li>
                    <li>In the dialog box, click on <strong>Change Password</strong><br/>
                        <img alt="Change Password" src={require("../../assets/images/mac-003.png")}/>
                    </li>

                </ol>
            </div>
        </div>
    );
};

export default PcMac;
