// StepContextProvider.jsx

import React, {useReducer} from 'react';
import StepContext from './StepContext';

const reducer = (state, action) => {
    switch(action.type) {
        case 'movePage':
            return {
                ...state,
                currPage: action.payload.currPage,
                lastMessage: action.payload.lastMessage,
            };
        case 'updateMessage':
            return {
                ...state,
                lastMessage: action.payload.lastMessage
            };
        default:
            return state;
    }
};

const StepContextProvider = ({ initialState, children}) => {

    return (
            <StepContext.Provider value={useReducer(reducer, initialState)}>
                {children}
            </StepContext.Provider>
    )
};

export default StepContextProvider;
