// Step-by-step AccountRakumo.jsx

import React, {useEffect} from 'react';

const AccountRakumo2 = () => {
    useEffect(() => {
        console.log('useEffect');
        setTimeout(function () {
            console.log('Set timeout');
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1><span>Rakumo Calendar / Workflow&nbsp;</span><span>(2 of 2)</span></h1>
            <ol>
                <li>Click on the <strong>Rakumo Calendar</strong> icon to log in</li>
                <li>The first time you open Rakumo, you will need to enter the <strong>Company ID</strong></li>
                <li>Please enter: <em>hennge.com</em><br/>
                    <img alt="Rakumo" src={require("../../../assets/images/rakumo-002.png")}/>
                </li>
                <li>Click <strong>Login</strong></li>
                <li>Make sure you can view your schedule and that there are no errors during login</li>
                <li>Please return to the HENNGE One portal.</li>
            </ol>
        </div>
    );
};

export default AccountRakumo2;
