// AppWithAuth.jsx

import { Box, Button, Paper, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import React, { useEffect, useMemo, useState } from 'react';
import '../src/assets/images/HENNGE-logo.png';
import img from '../src/assets/images/HENNGE-logo.png';
import App from './App';
import Cache from './Cache';
import UserContextProvider from './UserContextProvider';

const AppWithAuth = () => {
    const [authState, setAuthState] = useState('signIn');

    const initialUser = useMemo(() => {
        return Cache.getItems([
            'henngeAccount',
            'username',
            'currPage',
            'lastMessage',
        ]);
    }, []);

    const handleSAML = () => {
        Auth.federatedSignIn({
            customProvider: 'HENNGE',
        });
    };

    // persist login if user refreshes page
    useEffect(() => {
        const { localAuthState } = Cache.getItems(['localAuthState']);

        if (localAuthState === 'signedIn') {
            setAuthState('signedIn');
        }
    }, []);

    return (
        <Box display='flex' justifyContent='center' alignItems='center'>
            {authState !== 'signedIn' && (
                <Paper
                    elevation={3}
                    sx={{
                        width: { md: '33vw', xs: '100vw' },
                        height: { md: '65vh', xs: '100vh' },
                        marginTop: { md: '7rem', xs: 0 },
                        padding: 3,
                    }}
                >
                    <Box
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        flexDirection='column'
                        sx={{
                            height: '90%',
                        }}
                    >
                        <Box margin={3}>
                            <Typography variant='h5'>
                                Sign in to your account
                            </Typography>
                        </Box>
                        <Box margin={1} sx={{ width: '85%' }}>
                            <Button
                                variant='contained'
                                sx={{
                                    textTransform: 'none',
                                    width: '100%',
                                    height: '4rem',
                                    color: '#1c2e4a',
                                }}
                                size='large'
                                onClick={handleSAML}
                                startIcon={
                                    <Box
                                        component='img'
                                        src={img}
                                        alt='HENNGE logo'
                                        sx={{ height: '4rem' }}
                                    />
                                }
                            >
                                Sign In with HENNGE One
                            </Button>
                        </Box>
                    </Box>
                </Paper>
            )}

            <UserContextProvider user={initialUser} authState={authState}>
                <App />
            </UserContextProvider>
        </Box>
    );
};

export default AppWithAuth;
