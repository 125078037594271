// PcVaioIntune2.jsx

import React, {useEffect} from 'react';

const PcVaioIntune2 = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
            <div className="window-content">
                <h1><span>Windows&nbsp;</span><span>- VAIO - Windows Hello (1 of 7)</span></h1>
                <h3>Setting up Windows Hello PIN</h3>
                <div id="vaio" className="guidance-sub-sub-block">
                    <ol>
                        <li className="override">Right click on the <strong>Start</strong> button and choose <strong>Settings</strong><br/>
                            <img alt="Microsoft Security Confirmation" src={require("../../assets/images/vaio-intune-003.png")}/>
                        </li>
                    </ol>
                </div>
            </div>
    );
};

export default PcVaioIntune2;
