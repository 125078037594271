// PcVaioIntune.jsx

import React, {useEffect} from 'react';

const PcVaioIntune = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
            <div className="window-content">
                <h1><span>Windows&nbsp;</span><span>- VAIO - Login</span></h1>
                <div id="vaio" className="guidance-sub-sub-block">
                    <ol>
                        <li>Please turn on your laptop.</li>
                        <li>At the Windows login screen, please input the <strong>temporary password</strong> provided to you by IIT.</li>
                        <li>Windows should now take you to the desktop.</li>
                    </ol>
                </div>
            </div>
    );
};

export default PcVaioIntune;
