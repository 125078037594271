// Step-by-step SecurityRules.jsx

import React, {useEffect} from 'react';

const SecurityRules2 = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1><span>Security Rules&nbsp;</span><span>(2 of 4)</span></h1>
            <div className="guidance-sub-block">
                <h2>Information Devices</h2>
                <ul>
                    <li>Your PC is already set with a screensaver that will lock and require a password within 5 minutes. <br/>Do not change this setting.</li>
                    <li>Do not connect your personal media (memory card, etc) to provided information devices. <br/><strong>This includes your personal smartphone.</strong></li>
                    <li>Follow the required procedures when you take out information outside of the office via USB, CD, DVD. You can ask your manager for more details if you need to do this.</li>
                    <li>Do not install unnecessary apps.</li>
                </ul>
            </div>
        </div>
    );
};

export default SecurityRules2;
