// Step-by-step KisiApp4.jsx

import React, {useEffect} from 'react';

const KisiApp5 = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
            <div className="window-content">
                <h1><span>Kisi Application&nbsp;</span><span>(5 of 5)</span></h1>
                <ol start="11">
                    <li>
                        <div className="img-with-text">
                            <span>You are all set!</span><br/>
                            <img alt="Kisi Home Screen" src={require("../../assets/images/kisi-010.png")}/>
                        </div>
                    </li>
                    <li>
                        <div className="img-with-text">
                        <span>You can now use this app to unlock doors in the Shibuya offices, by tapping the card reader with your phone,
                            or by tapping a key icon of the door in the app!</span>
                            <div>
                                <img alt="Kisi Reader" src={require("../../assets/images/kisi-011.jpg")}/>
                                <img alt="Kisi Doors" src={require("../../assets/images/kisi-012.png")}/>
                            </div>
                        </div>
                    </li>
                    <li>The only difference between using the App and using the Kisi Card is&nbsp;
                        <strong>the App cannot be used with the printers in the Shibuya office</strong> - you will need to register another IC card or your smartphone to use the printers.</li>
                    <li>Please continue to the next section</li>
                </ol>
            </div>
    );
};
export default KisiApp5;
