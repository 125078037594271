// Cache.jsx

const updateToLocalStorage = (itemsToUpdate = {}) => {
    Object.keys(itemsToUpdate).forEach(item => {
        if (typeof itemsToUpdate[item] === 'object') {
            localStorage.setItem(item, JSON.stringify(itemsToUpdate[item]));
        } else {
            localStorage.setItem(item, itemsToUpdate[item]);
        }
    });
};

// itemsToGet = array of names
const getFromLocalStorage = (itemsToGet = []) => {
    const cached = {};
    itemsToGet.forEach(name => {
        const tmp = localStorage.getItem(name);
        if (tmp) {
            if (tmp.indexOf('{') > -1) {
                try {
                    cached[name] = JSON.parse(tmp);
                } catch (err) {
                    console.log('Expected JSON in localStorage: ', err);
                    cached[name] = tmp;
                }
            } else if (tmp.indexOf('[') > -1) {
                try {
                    cached[name] = JSON.parse(tmp);
                } catch (err) {
                    console.log(
                            "Can't parse array string with JSON parse",
                            err,
                    );
                    cached[name] = tmp;
                }
            } else {
                cached[name] = tmp;
            }
        }
    });
    return cached;
};

const clearAllLocalStorage = () => {
    localStorage.clear();
};

const removeFromLocalStorage = (itemsToRemove = []) => {
    itemsToRemove.forEach(name => {
        localStorage.removeItem(name);
    });
};

const Cache = {
    getItems: getFromLocalStorage,
    updateItems: updateToLocalStorage,
    removeItems: removeFromLocalStorage,
    clearAll: clearAllLocalStorage,
};

export default Cache;
