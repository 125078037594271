// Step-by-step AccountSlack.jsx

import React, {useEffect} from 'react';

const AccountSlack6 = () => {
    useEffect(() => {
        console.log('useEffect');
        setTimeout(function () {
            console.log('Set timeout');
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1><span>Slack&nbsp;</span><span>(6 of 6)</span></h1>
            <ol start="11">

                <li className="override">Change the <strong>Full name</strong> field to read your name as <em>FirstName LastName</em><br/>
                    <img alt="Full Name" src={require("../../../assets/images/slack-new-009.png")}/>
                    <ul>
                        <li>The reason for doing this is to allow others to search for you by your first or last name. <br/>If there is a dot connecting the two, you can only search the full text (firstname.lastname)</li>
                    </ul>
                </li>
                <li>You may set your <strong>Display Name</strong> and <strong>Profile Photo</strong> to anything you like, or leave them as they are.</li>
                <li>Click <strong>Save Changes</strong></li>
                <li>Please return to the HENNGE One portal.</li>
            </ol>
        </div>
    );
};

export default AccountSlack6;
