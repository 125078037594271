// Step-by-step AccountFzero.jsx

import React, {useEffect} from 'react';

const AccountFzero2 = () => {
    useEffect(() => {
        console.log('useEffect');
        setTimeout(function () {
            console.log('Set timeout');
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1><span>Fzero&nbsp;</span><span>(2 of 2)</span></h1>
            <ol>
                <li>Click on the <strong>Fzero icon</strong> to log in</li>
                <li>Confirm you can view the overview screen<br/>
                    <div className="override"><img className="override" alt="Rakumo" src={require("../../../assets/images/fzero-002.png")}/></div>
                </li>
                <li>Please return to the HENNGE One portal.</li>
            </ol>
        </div>
    );
};

export default AccountFzero2;
