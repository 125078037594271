// PcVaioIntune4.jsx

import React, {useEffect} from 'react';

const PcVaioIntune4 = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
            <div className="window-content">
                <h1><span>Windows&nbsp;</span><span>- VAIO - Windows Hello (3 of 7)</span></h1>
                <div id="vaio" className="guidance-sub-sub-block">
                    <ol start="3">
                        <li className="override"> From the "Sign-in options" screen, select <strong>PIN (Windows Hello)</strong> and click <strong>Change PIN</strong><br/>
                            <img alt="Microsoft Security Confirmation" src={require("../../assets/images/vaio-intune-005.png")}/>
                        </li>
                    </ol>
                </div>
            </div>
    );
};

export default PcVaioIntune4;
