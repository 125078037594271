// Step-by-step AccessControlChromebook.jsx

import React, {useEffect} from 'react';
import ScrollToTopOnMount from "../StepByStep/ScrollToTopOnMount";

const AccessControlChromebook = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <ScrollToTopOnMount/>
            <h1><span>HENNGE Access&nbsp;</span><span>Control (SSO) Setup&nbsp;</span><span>(1 of 6)</span></h1>
            <div className="description-text">
                <div className="vertical-padding">HENNGE Access Control is the Single-Sign On (SSO) service and is part of our product, HENNGE One.
                    Almost all the services we use for business can be logged in through SSO.</div>
                <div className="vertical-padding">HENNGE Access Control has a portal site that you will use for most applications. </div>
                <div className="vertical-padding">We call this portal site <strong>HENNGE One Portal</strong>.
                    We'll set this up by opening Chrome on your computer - please enter this link:</div>
                <div className="important-link"><a href="https://ap.ssso.hdems.com/portal/hennge.com/"><span>https://ap.ssso.hdems.com/</span><span>portal/</span><span>hennge.com/</span></a> </div>
                <div className="special-note center">(the slash at the end is important, please do not forget it!)</div>
            </div>
            <ol>
                <li>On your Chromebook, you will need to enter your username and password to log in to HENNGE One Portal.</li>
                <li>Please enter your username and the temporary password provided to you by the person in charge of this training.</li>
                <li>You will be asked to enter a <strong>TOTP token</strong>. This time, ask the person in charge of this training to give you a temporary OTP.</li>
                <li>Please input the temporary OTP.</li>
            </ol>
        </div>
    );
};

export default AccessControlChromebook;
