import Amplify from 'aws-amplify';
import React, { useContext } from 'react';
import './App.css';
import awsmobile from './aws-exports';
import StepByStep from './components/StepByStep/StepByStep';
import StepContextProvider from './components/StepByStep/StepContextProvider';
import StepGreetings from './components/StepByStep/StepGreetings';
import UserContext from './UserContext';

Amplify.configure(awsmobile);

const stepTheme = {
    navButton: {
        fontWeight: 'bold',
    },
    nav: {
        display: 'flex',
    },
};

const App = ({ authState }) => {
    console.log('authState: ', authState);
    const state = useContext(UserContext);
    console.log('UserContext state: ', state);
    const currPage = state.currPage || 'introduction';
    const lastMessage = state.lastMessage || '';

    if (authState === 'signedIn') {
        return (
            <div className='App'>
                <StepContextProvider
                    initialState={{
                        currPage,
                        lastMessage,
                    }}
                >
                    <StepGreetings theme={stepTheme} />
                    <StepByStep />
                </StepContextProvider>
            </div>
        );
    } else {
        return null;
    }
};

export default App;