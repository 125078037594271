// Step-by-step GoogleChat.jsx

import React, {useEffect} from 'react';

const GoogleChat3 = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
            <div className="window-content">
                <h1><span>Google Chat&nbsp;</span><span>(3 of 3)</span></h1>
                <ol start="4">
                    <li>
                        <span>Once the status changes to <strong>joined</strong>, you're finished.</span><br/>
                        <img alt="Browse Spaces" src={require("../../assets/images/google-chat-003.png")}/>
                    </li>
                    <li>Please continue to the next section</li>
                </ol>
            </div>
    );
};

export default GoogleChat3;
