// Step-by-step AccountKaonavi.jsx

import React, {useEffect} from 'react';

const AccountKaonavi = () => {
    useEffect(() => {
        console.log('useEffect');
        setTimeout(function () {
            console.log('Set timeout');
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1>Kaonavi</h1>
            <div className="img-with-text">
                <div className="img-container">
                    <img alt="Kaonavi" src={require("../../../assets/images/kaonavi-001.png")}/>
                </div>
                <div className="description-text">
                    Only full-time employees can have access to Kaonavi.<br/><br/>
                    Kaonavi is HR software that you will use to manage your annual performance reviews
                    and view your TOEIC test scores. You can also use it to view information about yourself
                    and your coworkers, but if you're looking for a picture of someone, we recommend you use the Slack bot&nbsp;
                    <strong>Anego</strong> instead!
                </div>
            </div>
            <ol>
                <li>Click on the <strong>Kaonavi icon</strong> to log in</li>
                <li>Confirm login is successful <br/>
                    <img alt="Kaonavi" src={require("../../../assets/images/kaonavi-002.png")}/>
                </li>
                <li>Please return to the HENNGE One portal.</li>
            </ol>
        </div>
    );
};

export default AccountKaonavi;
