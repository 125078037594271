// Step-by-step Isms3.jsx

import React, {useContext, useEffect} from 'react';
import StepContext from "../StepByStep/StepContext";
import Cache from "../../Cache";

const handleOnClick = (e, dispatch, newPage) => {
    e.preventDefault();
    console.log('handleOnClick newPage: ', newPage);
    if(newPage) {
        Cache.updateItems({currPage: newPage});
        dispatch({
            type: 'movePage',
            payload: {
                currPage: newPage,
            },
        });
    }
};

const Isms3 = () => {
    const [, dispatch] = useContext(StepContext);
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
            <div className="window-content">
                <h1><span>ISMS Training&nbsp;</span><span>(3 of 3)</span></h1>
                <ol start="5">
                    <li>Once you have completed reading the training material, please return to the
                        ISMS page to take the <strong>Confirmation Test</strong>.
                    </li>
                    <li>When you have completed the test, please click the button below:</li>
                </ol>
                <div className="button-choice-container">
                    <a href="/#" onClick={e => {
                        handleOnClick(e, dispatch, 'guidance-complete');
                    }}><div className="button-choice complete">
                        I have completed reading the ISMS manual and have taken the Confirmation Test
                    </div></a>
                </div>
            </div>
    );
};

export default Isms3;
