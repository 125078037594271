// Step-by-step AccountPinkPony.jsx

import React, {useEffect} from 'react';

const AccountPinkPony = () => {
    useEffect(() => {
        console.log('useEffect');
        setTimeout(function () {
            console.log('Set timeout');
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1>Pink Pony</h1>
            <div className="img-with-text">
                <div className="img-container">
                    <img alt="Pink Pony" src={require("../../../assets/images/pinkpony-001.png")}/>
                </div>
                <div className="description-text">
                    Pink Pony is a document search platform.
                    It grants you access to all information related to internal communication.
                    You can search for keywords in official documents such as information about
                    reimbursements, paid holidays, and so on. You can also find where people are
                    in the office based on what wifi they are connected to, but we'd recommend
                    asking Aniki through Slack instead!<br/>
                </div>
            </div>

            <div className="margin-buffer">
                <ol>
                    <li>
                        <div className="img-with-text">
                            <span>Please click on the <strong>Pink Pony icon</strong> to confirm you can view the page</span>
                            <img alt="Pink Pony" src={require("../../../assets/images/pinkpony-002.png")}/>
                        </div>
                    </li>
                    <li>
                        Please return to the HENNGE One portal
                    </li>
                </ol>
            </div>
        </div>
    );
};

export default AccountPinkPony;
