// Step-by-step AccessControlChromebook2.jsx

import React, {useEffect} from 'react';
import ScrollToTopOnMount from "../StepByStep/ScrollToTopOnMount";

const AccessControlChromebook2 = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <ScrollToTopOnMount/>
            <h1><span>HENNGE Access&nbsp;</span><span>Control (SSO) Setup&nbsp;</span><span>(2 of 6)</span></h1>
            <ol start="5">
                <li>After changing your password, you will need to configure your One Time Password (OTP) settings.</li>
                <li>In the top right-hand corner of the main screen, you will see your name and a drop-down arrow - click it to reveal this menu:<br/>
                    <img alt="User Menu" src={require("../../assets/images/hac-001.png")}/>
                </li>
            </ol>
        </div>
    );
};

export default AccessControlChromebook2;
