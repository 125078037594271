// Step-by-step KisiApp3.jsx

import React, {useEffect} from 'react';

const KisiApp3 = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
            <div className="window-content">
                <h1><span>Kisi Application&nbsp;</span><span>(3 of 5)</span></h1>
                <ol start="6">
                    <li>
                        <span>Select <strong>SSO Sign In</strong></span><br/>
                        <img alt="Kisi on App Store" src={require("../../assets/images/kisi-004-new.png")}/>
                    </li>
                    <li>
                        <div className="img-with-text">
                            <span>You will be taken to <strong>HENNGE One Access Control</strong> - please log in with your username and password that were created in the Access Control step earlier in this training</span>
                            <img alt="Kisi on App Store" src={require("../../assets/images/kisi-005.png")}/>
                        </div>
                    </li>
                </ol>
            </div>
    );
};
export default KisiApp3;
