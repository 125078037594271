// PcVaio.jsx

import React, {useEffect} from 'react';
import '../StepByStep/StepByStep.css';

const WindowsLogin3 = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1>Change Windows Password (3 of 3)</h1>
            <div id="windows-login" className="guidance-sub-sub-block">
                <ol start="6">
                    <li>Input the temporary password for the current password (現在のパスワード) and click &ldquo;Next/次へ&rdquo; button.<br/>
                        <img alt="Temporary Password" src={require("../../assets/images/windows-004.png")}/>
                    </li>
                    <li>Input your own password for the new password (新しいパスワード) and confirmation (パスワードの確認入力).<br/>
                        <img alt="New Password" src={require("../../assets/images/windows-005.png")}/>
                    </li>
                    <li>Please set any password hint and then click <strong>&ldquo;次へ&rdquo;</strong> button.</li>
                    <li>Click <strong>&ldquo;&#23436;&#20102;&rdquo;</strong> and the Windows password change is completed. Click next to go to the next section</li>
                </ol>
            </div>
        </div>
    );
};
export default WindowsLogin3;
