import { Box, Typography } from '@mui/material';
import React from 'react';

const GuidanceHeader = () => (
    <Box>
        <Typography
            variant='h1'
            sx={{
                fontSize: { xs: '1.8rem', md: '3rem' },
                marginLeft: { xs: 0, md: 22 },
            }}
        >
            HENNGE Welcome Guidance
        </Typography>
    </Box>
);

export default GuidanceHeader;
