// PcDell.jsx

import React, {useEffect} from 'react';

const PcDell = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1><span>Windows&nbsp;</span><span>- DELL (1 of 2)</span></h1>
            <div className="description-text">
                For Dell Machines, you will need to set up a BIOS password and Windows login password.</div>
            <h3>Change BIOS and HDD passwords</h3>
            <div id="dell" className="guidance-sub-sub-block">
                <ol>
                    <li>Make sure that your <strong>computer is turned off</strong>.</li>
                    <li>Push the <strong>power button</strong> and press <strong>[F2]</strong> when DELL logo appears.<br/>
                        * NOTE! DELL logo will be shown just for a second! Don&#39;t miss it!
                    </li>
                    <li>You will be asked to enter the <strong>BIOS password</strong>.</li>
                    <li>
                        Please input the temporary BIOS password provided to you by the person
                        in charge of this training.
                    </li>
                    <li>Press the <strong>Enter</strong> key.</li>
                    <li>BIOS Setup will be shown.</li>
                    <li>
                        <div className="list-inline-img flex-with-wrap">
                            <span>Select <strong>Passwords</strong> menu from the left side.</span>
                            <img alt="DELL Password Menu" src={require("../../assets/images/dell-001.png")}/>
                        </div>
                    </li>
                </ol>
            </div>
        </div>
    );
};

export default PcDell;
