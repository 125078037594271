// Step-by-step AccessControl2.jsx

import React, {useEffect} from 'react';
import '../StepByStep/StepByStep.css';
import PasswordRules from "../PasswordRules";

const AccessControl2 = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1><span>HENNGE Access&nbsp;</span><span>Control (SSO)&nbsp;Setup </span><span>(2 of 6)</span></h1>
            <ol start="6">
                <li>Enter your temporary password provided to you by the person
                    in charge of this training here</li>
                <li>Choose a new password which is different from your computer password(s). It should follow the same rules as before:
                    <PasswordRules/>
                </li>
                <li>After changing your password, you will need to configure your One Time Password (OTP) settings.</li>
                <li>In the top right-hand corner of the main screen, you will see your name and a drop-down arrow - click it to reveal this menu:<br/>
                    <img alt="User Menu" src={require("../../assets/images/hac-001.png")}/>
                </li>
            </ol>
        </div>
    );
};

export default AccessControl2;
