// Step-by-step AccountSecureTransfer.jsx

import React, {useEffect} from 'react';

const AccountSecureTransfer = () => {
    useEffect(() => {
        console.log('useEffect');
        setTimeout(function () {
            console.log('Set timeout');
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1>HENNGE Secure Transfer</h1>
            <div className="img-with-text">
                <div className="img-container">
                    <img alt="HENNGE Secure Transfer" src={require("../../../assets/images/hos-001.png")}/>
                </div>
                <div className="description-text">
                    HENNGE Secure Transfer provides a platform to upload and exchange files securely. <br/>
                    Large files can be transferred between users/customers.<br/>
                    This is also part of HENNGE One.
                </div>
            </div>
            <ol>
                <li>Click on the <strong>HST Modern icon</strong> to log in.</li>
                <li>Confirm you can view the overview page:<br/>
                    <img alt="HENNGE Secure Transfer" src={require("../../../assets/images/hos-002.png")}/>
                </li>
                <li>Please return to the HENNGE One portal.</li>
            </ol>
        </div>
    );
};

export default AccountSecureTransfer;
