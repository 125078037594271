// Step-by-step Isms.jsx

import React, {useEffect} from 'react';

const Isms = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1><span>ISMS Training&nbsp;</span><span>(1 of 2)</span></h1>
            <div className="description-text">
                It is essential that <strong>all members (including temporary staff, outsourcers, and interns)</strong> complete ISMS training before starting their work at HENNGE.<br/>
                In accordance with the ISO 27001 standard, we maintain an <strong>ISMS (Information Security Management System)</strong> to manage our information security.
                We have been ISO 27001 certified since December 5th, 2007.
                In order to stay certified, and to prevent security incidents as much as possible, it is important for everyone to understand and follow our security rules.
            </div>
            <ol>
                <li>Please go to the <a href="https://sites.google.com/hennge.com/henngedocuments/isms" target="ismswin">ISMS Page on HENNGE Documents</a>
                    <ul>
                        <li><div className="description-note">You can also access via the HENNGE One Portal page</div></li>
                        <li><div className="description-note">From the main page of HENNGE Documents find ISMS in the sidebar menu</div></li>
                    </ul>
                </li>
                <li>You will be asked to verify your Google Account - please enter your full email in the format <strong>firstname.lastname@hennge.com</strong></li>
                <li>On the ISMS page, please open the ISMS Manual. You may choose the language most comfortable for you.<br/>
                    <a href="https://sites.google.com/hennge.com/henngedocuments/isms" target="ismswin"><img alt="ISMS Manual" src={require("../../assets/images/isms-002.png")}/>
                        <img alt="ISMS Test" src={require("../../assets/images/isms-003.png")} className="override-tall" /></a>
                </li>
            </ol>
        </div>
    );
};

export default Isms;
