// Step-by-step AccountSlack.jsx

import React, {useEffect} from 'react';

const AccountSlack2 = () => {
    useEffect(() => {
        console.log('useEffect');
        setTimeout(function () {
            console.log('Set timeout');
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1><span>Slack&nbsp;</span><span>(2 of 6)</span></h1>
            <ol>
                <li>Click on the <strong>Slack icon</strong> in the HENNGE One portal to log in to Slack<br/><br/></li>
                <li>
                    <div className="img-with-text">
                        <span>Click on "I Agree" after reviewing the terms of service<br/></span>
                        <img alt="Agree to ToS" src={require("../../../assets/images/slack-new-001.png")}/>
                    </div>
                </li>
                <li>
                    <div className="img-with-text">
                        <span>On the Workspaces dashboard, click on "View Details" for the HENNGE workspace<br/></span>
                        <img alt="View Details for HENNGE workspace" src={require("../../../assets/images/slack-new-002.png")}/>
                    </div>
                </li>
            </ol>
        </div>
    );
};

export default AccountSlack2;
