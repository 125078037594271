// Step-by-step SecurityRules.jsx

import React, {useEffect} from 'react';

const SecurityRules1 = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1><span>Security Rules&nbsp;</span><span>(1 of 4)</span></h1>
            <div className="description-text">
                Before we continue with configuring accounts, let's review HENNGE's security rules.
                Please read carefully and make sure you understand them completely. If anything is unclear, please ask.
                It's important to follow these guidelines to prevent incidents like information leakage.
            </div>
            <div className="guidance-sub-block">
                <h2>Entering &amp; Leaving the Office</h2>
                <ul>
                    <li>Everyone must use their own IC card to enter or leave Shibuya office.
                        <ul>
                            <li>For those working in branch offices, please use the access card you are provided for those offices.</li>
                        </ul>
                    </li>
                    <li>The last person to leave the office must securely lock the office.
                        <ul>
                            <li>You can check the <strong>How to Open/Close Office</strong> in HENNGE Documents
                                for details on First/Last Person in the Office Procedures</li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div className="guidance-sub-block">
                <h2>Clean Desk &amp; Locker Management</h2>
                <ul>
                    <li>You must not leave your devices unattended for <strong>longer than 30 minutes</strong> even on 2F/4F/5F in Shibuya office.
                        On 11F while it is open for visitors, or in areas accessible to non-HENNGE members in branch offices,
                        <strong> you must not leave items unattended</strong> for any amount of time.</li>
                    <li>Put devices or documents inside a locker or take them with you when you are not using them. You must lock the locker when it is closed.</li>
                    <li>Any time you will be away from your desk for more than a <strong>short period of time</strong> you must clear your seat.
                        For example, if you will leave to attend a meeting for <strong>more than 30 minutes</strong>, leave to go out to lunch,
                        and of course when you go home, or leave the office for any other reason.</li>
                    <li>Documents with personal information must always be stored in lockable cabinets.</li>
                </ul>
            </div>
        </div>
    );
};

export default SecurityRules1;
