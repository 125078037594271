// PcVaioIntune8.jsx

import React, {useEffect} from 'react';

const PcVaioIntune8 = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
            <div className="window-content">
                <h1><span>Windows&nbsp;</span><span>- VAIO - Windows Hello (7 of 7)</span></h1>
                <h3>(OPTIONAL) Setting up Windows Hello FACE</h3>
                <div id="vaio" className="guidance-sub-sub-block">
                    <ol start="11">
                        <li>On the "Sign-in options" screen, select <strong>FACIAL RECOGNITION (Windows Hello)</strong> and click <strong>Set up</strong><br/>
                        </li>
                        <li>On the prompt, click <strong>Get Started</strong> then input your <strong>PIN</strong></li>
                        <li>The camera will automatically turn ON, keep looking directly at the camera</li>
                        <li>When you see this message, you’re done! You can improve recognition anytime if needed.<br/>
                            <img alt="Microsoft Security Confirmation" src={require("../../assets/images/vaio-intune-014.png")}/>
                        </li>
                        <li>Click <strong>Close</strong></li>
                        <li>Please continue to the next section of this guide</li>
                    </ol>
                </div>
            </div>
    );
};

export default PcVaioIntune8;
