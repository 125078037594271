// Step-by-step AccountSetup.jsx

import React, {useEffect} from 'react';

const AccountSetup = () => {
    useEffect(() => {
        console.log('useEffect');
        setTimeout(function () {
            console.log('Set timeout');
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1>Accounts Setup</h1>
            <div className="description-text">
                Now, we will walk you through accessing and confirming your accounts for several services using the <strong>HENNGE One portal</strong>.
                If at any point you encounter something that you cannot access or
                do not understand if it is working correctly, please reach out to a member of the Internal IT team. <br/><br/>

                Your HENNGE One portal may look slightly different from this, depending on what services are available to you in your role and section:<br/>
            </div>
            <div className="center-img">
                <img alt="HENNGE One Portal" src={require("../../../assets/images/accounts-001.png")}/>
            </div>
            <div className="description-text">
                We'll go through services one by one, so please follow each step carefully. After each step, please return to the HENNGE One Portal.
            </div>
        </div>
    );
};

export default AccountSetup;
