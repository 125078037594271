// PcVaio.jsx

import React, {useEffect} from 'react';
import '../StepByStep/StepByStep.css';

const WindowsLogin2 = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1>Change Windows Password (2 of 3)</h1>
            <div id="windows-login" className="guidance-sub-sub-block">
                <ol start="4">
                    <li>Select &ldquo;サインインオプション&rdquo; and click &ldquo;パスワード&rdquo; section. <br/>
                        <img alt="Sign In Option" src={require("../../assets/images/windows-003-1.png")}/>
                    </li>
                    <li>
                        Click the “変更” button.<br/>
                        <img alt="Change password menu" src={require("../../assets/images/windows-004-1.png")}/>
                    </li>
                </ol>
            </div>
        </div>
    );
};

export default WindowsLogin2;
