// PcVaioIntune5.jsx

import React, {useEffect} from 'react';

const PcVaioIntune5 = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
            <div className="window-content">
                <h1><span>Windows&nbsp;</span><span>- VAIO - Windows Hello (4 of 7)</span></h1>
                <div id="vaio" className="guidance-sub-sub-block">
                    <ol start="4">
                        <li>Check <strong>“Include letters and symbols”</strong></li>
                        <li className="override">Input the Temporary Password as PIN then type new PIN and click OK. Must be 8 characters or more.<br/>
                            <img alt="Change PIN" src={require("../../assets/images/vaio-intune-pin-006.png")}/>
                        </li>
                    </ol>
                </div>
            </div>
    );
};

export default PcVaioIntune5;
