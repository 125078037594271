// Step-by-step AccountSlack.jsx

import React, {useEffect} from 'react';

const AccountSlack5 = () => {
    useEffect(() => {
        console.log('useEffect');
        setTimeout(function () {
            console.log('Set timeout');
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1><span>Slack&nbsp;</span><span>(5 of 6)</span></h1>
            <ol start="10">
                <li className="override-tall">
                        <span>On the pop-up, click on <strong>Edit</strong> next to your full name</span><br/>
                        <img alt="Full Name" src={require("../../../assets/images/slack-new-008.png")}/>
                </li>
            </ol>
        </div>
    );
};

export default AccountSlack5;
