// Step-by-step AccountGmail.jsx

import React, {useEffect} from 'react';

const AccountGmail = () => {
    useEffect(() => {
        console.log('useEffect');
        setTimeout(function () {
            console.log('Set timeout');
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1><span>Gmail&nbsp;</span><span>(1 of 2)</span></h1>
            <div className="img-with-text header">
                <div className="img-container">
                    <img alt="Gmail" src={require("../../../assets/images/gmail-001.png")}/>
                </div>
                <div className="description-text">
                    We use GSuite as our groupware service, so we use Gmail for our email. <br/>
                    There is one setting you must change before beginning to use Gmail here.
                </div>
            </div>
            <ol>
                <li>Click on the <strong>Gmail icon</strong> to log in.</li>
                <li>
                    <div className="img-with-text">
                        <div>
                            After logging in, please find the <strong>gear icon</strong>
                                <img alt="Gear icon" src={require("../../../assets/images/gmail-gear.png")} className="mini align-center"/>
                                in the top right-hand corner of the screen, and click on it
                        </div>
                        <img alt="Settings" src={require("../../../assets/images/gmail-002.png")}/>
                    </div>
                </li>
                <li>In that menu, please click on <strong>See all settings</strong></li>
            </ol>
        </div>
    );
};

export default AccountGmail;
