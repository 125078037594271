// GuidanceWindow.jsx

import React from 'react';
import WindowContent from "./WindowContent";
import Navigation from "./Navigation";
import './StepByStep.css';

const GuidanceWindow = () => (
        <main role="main" className="guidance-container">
            <div className="guidance-window">
                <WindowContent/>
                <Navigation/>
            </div>
        </main>
);

export default GuidanceWindow;