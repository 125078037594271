// Step-by-step AccountSlack.jsx

import React, {useEffect} from 'react';

const AccountSlack4 = () => {
    useEffect(() => {
        console.log('useEffect');
        setTimeout(function () {
            console.log('Set timeout');
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1><span>Slack&nbsp;</span><span>(4 of 6)</span></h1>
            <ol start="7">
                <li>
                    On the new tab with Slack open, click on <strong>Let's Do It</strong><br/>
                </li>
                <li>
                    <div className="img-with-text">
                    <span>The next page shows several HENNGE members you can put on your sidebar, you can do this later if you like,
                        so click <strong>Skip for now</strong></span>
                    <img alt="User menu" src={require("../../../assets/images/slack-new-006.png")}/>
                    </div>
                </li>
                <li>
                    <div className="img-with-text">
                        <span>In the top right-hand corner, click on the square icon to open the menu, and then click on <strong>Profile</strong></span>
                        <img alt="User menu" src={require("../../../assets/images/slack-new-007.png")}/>
                    </div>
                </li>
            </ol>
        </div>
    );
};

export default AccountSlack4;
