// Step-by-step AccountGDrive.jsx

import React, {useEffect} from 'react';

const AccountGDrive = () => {
    useEffect(() => {
        console.log('useEffect');
        setTimeout(function () {
            console.log('Set timeout');
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1>Google Drive</h1>
            <div className="img-with-text">
                <div className="img-container">
                    <img alt="Google Drive" src={require("../../../assets/images/gdrive-001.png")}/>
                </div>
                <div className="description-text">
                    Google Drive is the primary method of sharing and storing files internally. GSuite allows you to create Documents, Spreadsheets, and so on. <br/><br/>
                </div>
            </div>
            <ol>
                <li>Please click on the <strong>Google Drive</strong> icon to confirm you can view this service.</li>
                <li>Please note the following guidelines when using Google Drive:
                    <ul className="margin-buffer">
                        <li>We do allow sharing with external accounts (accounts other than hennge.com) but <strong>please be mindful</strong> about when you choose to do this</li>
                        <li>We recommend you <strong>DO NOT</strong> use open link sharing, where anyone with the link can access the file.</li>
                    </ul>
                </li>
                <li>Please return to the HENNGE One portal</li>
            </ol>
        </div>
    );
};

export default AccountGDrive;
