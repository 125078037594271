// Chromebook.jsx

import React, {useEffect} from 'react';

const Chromebook = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1>Chromebook (1 of 2)</h1>
            <div className="guidance-sub-sub-block">
                <ol>
                    <li>Open the laptop and select a network for internet access</li>
                    <li>On the Chromebook login screen, input your HENNGE email, including <strong>@hennge.com</strong><br/>
                        <img alt="Chrome login screen" src={require("../../assets/images/chromebook-001.jpg")}/>
                    </li>
                    <li>Click <strong>Next</strong></li>

                    <li>On the HENNGE login screen, input your full email and temporary password provided to you by the person
                        in charge of this training, then click <strong>Next</strong>.<br/>
                        <img alt="Chrome login screen" src={require("../../assets/images/chromebook-002.jpg")}/>
                    </li>
                </ol>
            </div>
        </div>
    );
};

export default Chromebook;
