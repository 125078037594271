// Step-by-step AccountHenngeDocuments.jsx

import React, {useEffect} from 'react';

const AccountHenngeDocuments = () => {
    useEffect(() => {
        console.log('useEffect');
        setTimeout(function () {
            console.log('Set timeout');
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1>HENNGE Documents</h1>
            <div className="img-with-text">
                <div className="img-container">
                    <img alt="HENNGE Documents" src={require("../../../assets/images/henngedoc-001.png")}/>
                </div>
                <div className="description-text">
                    <strong>HENNGE Documents</strong> is a portal site where you can find Regulations, Office manuals, etc.
                    You can find a lot of frequently asked questions regarding common topics such as how to print in Shibuya
                    office, or whether to use Zoom or Google Hangouts for your presentations.
                    There are also links to other specific portal pages such as HR Portal, Legal Portal, and so on.
                    Please check them later to become familiar with where to find different kinds of information!
                </div>
            </div>
            <ol className="margin-buffer">
                <li>
                    <div className="img-with-text">
                        <span>Click on the <strong>HENNGE Documents icon</strong> to view the page</span>
                        <img alt="HENNGE Documents Page" src={require("../../../assets/images/henngedoc-002.png")}/>
                    </div>
                </li>
                <li>
                    Please return to the HENNGE One portal.
                </li>
            </ol>
        </div>
    );
};

export default AccountHenngeDocuments;
