// WindowContent.jsx

import React, { useContext } from 'react';
import StepContext from './StepContext';
import Introduction from '../contents/Introduction';
import HowToUse from "../contents/HowToUse";
import SecurityRules1 from '../contents/SecurityRules1';
import SecurityRules2 from '../contents/SecurityRules2';
import SecurityRules3 from '../contents/SecurityRules3';
import SecurityRules4 from "../contents/SecurityRules4";
import PcSetupIntro from "../contents/PcSetupIntro";
import PcVaio from "../contents/PcVaioIntune";
import PcVaio2 from "../contents/PcVaioIntune2";
import PcVaio3 from "../contents/PcVaioIntune3";
import PcVaio4 from "../contents/PcVaioIntune4";
import PcVaio5 from "../contents/PcVaioIntune5";
import PcVaio6 from "../contents/PcVaioIntune6";
import PcVaio7 from "../contents/PcVaioIntune7";
import PcVaio8 from "../contents/PcVaioIntune8";
import PcDell from "../contents/PcDell";
import PcDell2 from "../contents/PcDell2";
import WindowsLogin from "../contents/WindowsLogin";
import WindowsLogin2 from "../contents/WindowsLogin2";
import WindowsLogin3 from "../contents/WindowsLogin3";
import PcMac from "../contents/PcMac";
import PcMac2 from "../contents/PcMac2";
import PcMac3 from '../contents/PcMac3';
import MacJamf from "../contents/MacJamf";
import MaciCloud from "../contents/MaciCloud";
import Chromebook from "../contents/Chromebook";
import Chromebook2 from "../contents/Chromebook2";
import AccessControl from "../contents/AccessControl";
import AccessControlChromebook from "../contents/AccessControlChromebook";
import AccessControlChromebook2 from "../contents/AccessControlChromebook2";
import AccessControl2 from "../contents/AccessControl2";
import AccessControl3 from "../contents/AccessControl3";
import AccessControl4 from "../contents/AccessControl4";
import AccessControl5 from "../contents/AccessControl5";
import AccessControl6 from "../contents/AccessControl6";
import OtpEmail from "../contents/OtpEmail";

import { AccountSetup, AccountSlack, AccountSlack2, AccountSlack3, AccountSlack4, AccountSlack5, AccountSlack6, AccountGmail,
AccountGmail2, AccountGDrive, AccountRakumo, AccountRakumo2, AccountFzero, AccountFzero2,
    AccountSecureTransfer, AccountTeamspirit,
AccountOffice, AccountSansan, AccountKaonavi, AccountKickflow, AccountPinkPony, AccountHenngeDocuments, AccountKeeper } from '../contents/AccountSetupPages';

import ChromeSync from "../contents/ChromeSync";
import GoogleChat from "../contents/GoogleChat";
import GoogleChat2 from "../contents/GoogleChat2";
import GoogleChat3 from "../contents/GoogleChat3";
import OfficeSecurity from '../contents/OfficeSecurity';
import KisiApp from "../contents/KisiApp";
import KisiApp2 from "../contents/KisiApp2";
import KisiApp3 from "../contents/KisiApp3";
import KisiApp4 from "../contents/KisiApp4";
import KisiApp5 from "../contents/KisiApp5";
import Isms from "../contents/Isms";
import Isms2 from '../contents/Isms2';
import Isms3 from '../contents/Isms3';
import GuidanceComplete from "../contents/GuidanceComplete";
import PasswordPolicy from "../contents/PasswordPolicy";

import './StepByStep.css';

const getPage = (currPage) => {
    switch(currPage) {
        case 'introduction':
            return (<Introduction/>);
        case 'how-to-use':
            return (<HowToUse/>);
        case 'security-1':
            return (<SecurityRules1/>);
        case 'security-2':
            return (<SecurityRules2/>);
        case 'security-3':
            return (<SecurityRules3/>);
        case 'security-4':
            return (<SecurityRules4/>);
        case 'pc-setup':
            return (<PcSetupIntro/>);
        case 'pc-vaio':
            return (<PcVaio/>);
        case 'pc-vaio-2':
            return (<PcVaio2/>);
        case 'pc-vaio-3':
            return (<PcVaio3/>);
        case 'pc-vaio-4':
            return (<PcVaio4/>);
        case 'pc-vaio-5':
            return (<PcVaio5/>);
        case 'pc-vaio-6':
            return (<PcVaio6/>);
        case 'pc-vaio-7':
            return(<PcVaio7/>);
        case 'pc-vaio-8':
            return(<PcVaio8/>);
        case 'pc-dell':
            return(<PcDell/>);
        case 'pc-dell-2':
            return(<PcDell2/>);
        case 'windows-login':
            return (<WindowsLogin/>);
        case 'windows-login-2':
            return (<WindowsLogin2/>);
        case 'windows-login-3':
            return (<WindowsLogin3/>);
        case 'pc-mac':
            return (<PcMac/>);
        case 'pc-mac-2':
            return (<PcMac2/>);
        case 'pc-mac-3':
            return (<PcMac3/>);
        case 'mac-jamf':
            return (<MacJamf/>);
        case 'mac-icloud':
            return (<MaciCloud/>);
        case 'pc-chromebook':
            return (<Chromebook/>);
        case 'pc-chromebook2':
            return (<Chromebook2/>);
        case 'access-control':
            return (<AccessControl/>);
        case 'access-control-chromebook':
            return (<AccessControlChromebook/>);
        case 'access-control-chromebook-2':
            return (<AccessControlChromebook2/>);
        case 'access-control-2':
            return (<AccessControl2/>);
        case 'access-control-3':
            return (<AccessControl3/>);
        case 'access-control-4':
            return (<AccessControl4/>);
        case 'access-control-5':
            return (<AccessControl5/>);
        case 'access-control-6':
            return (<AccessControl6/>);
        case 'otp-email':
            return (<OtpEmail/>);
        case 'account-setup':
            return (<AccountSetup/>);
        case 'account-slack':
            return (<AccountSlack/>);
        case 'account-slack-2':
            return (<AccountSlack2/>);
        case 'account-slack-3':
            return (<AccountSlack3/>);
        case 'account-slack-4':
            return (<AccountSlack4/>);
        case 'account-slack-5':
            return (<AccountSlack5/>);
        case 'account-slack-6':
            return (<AccountSlack6/>);
        case 'account-chrome-sync':
            return (<ChromeSync/>);
        case 'account-gmail':
            return (<AccountGmail/>);
        case 'account-gmail-2':
            return (<AccountGmail2/>);
        case 'account-gchat':
            return (<GoogleChat/>);
        case 'account-gchat-2':
            return (<GoogleChat2/>);
        case 'account-gchat-3':
            return (<GoogleChat3/>);
        case 'account-gdrive':
            return (<AccountGDrive/>);
        case 'account-keeper':
            return (<AccountKeeper/>);
        case 'account-kickflow':
            return (<AccountKickflow/>);
        case 'account-rakumo':
            return (<AccountRakumo/>);
        case 'account-rakumo-2':
            return (<AccountRakumo2/>);
        case 'account-fzero':
            return (<AccountFzero/>);
        case 'account-fzero-2':
            return (<AccountFzero2/>);
        case 'account-secure-transfer':
            return (<AccountSecureTransfer/>);
        case 'account-teamspirit':
            return (<AccountTeamspirit/>);
        case 'account-office':
            return (<AccountOffice/>);
        case 'account-sansan':
            return (<AccountSansan/>);
        case 'account-kaonavi':
            return (<AccountKaonavi/>);
        case 'account-pink-pony':
            return (<AccountPinkPony/>);
        case 'account-hennge-docs':
            return (<AccountHenngeDocuments/>);
        case 'office-security':
            return (<OfficeSecurity/>);
        case 'kisi-app':
            return (<KisiApp/>);
        case 'kisi-app-2':
            return (<KisiApp2/>);
        case 'kisi-app-3':
            return (<KisiApp3/>);
        case 'kisi-app-4':
            return (<KisiApp4/>);
        case 'kisi-app-5':
            return (<KisiApp5/>);
        case 'isms':
            return (<Isms/>);
        case 'isms-2':
            return (<Isms2/>);
        case 'isms-3':
            return (<Isms3/>);
        case 'guidance-complete':
            return (<GuidanceComplete/>);
        case 'password-policy':
            return (<PasswordPolicy/>);
        default:
            return (<Introduction/>);
    }
};

const WindowContent = () => {
    const [state, ] = useContext(StepContext);
    const { currPage } = state;
    return (
        <div className="content-window">
            {getPage(currPage)}
        </div>
);};

export default WindowContent;