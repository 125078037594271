// Step-by-step GoogleChat.jsx

import React, {useEffect} from 'react';

const GoogleChat = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
            <div className="window-content">
                <h1><span>Google Chat&nbsp;</span><span>(1 of 3)</span></h1>
                <div className="description-text">
                    Slack is our official communication platform, but when something goes wrong with Slack, we will use Google Chat as backup.
                    Please join the “official” space beforehand in order to be prepared to receive announcements when there are issues with Slack.
                    Ref: <a href="https://docs.google.com/presentation/d/1FZi6nRsSKlK8deyh3GWG7OW1k8UisKtVXLG-ltcSAmc/edit#slide=id.g8bae0e1796_0_50" target="_window">BCP Manual: Slack Outage</a>
                </div>
                <ol>
                    <li>While still in Gmail, there should be <strong>Chat</strong> on the left-hand side. Click on it to open the menu menu.<br/>
                        <img alt="Spaces in Google Chat" src={require("../../assets/images/google-chat-001.png")}/>
                    </li>
                </ol>
            </div>
    );
};

export default GoogleChat;
