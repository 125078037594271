// Step-by-step AccountTeamspirit.jsx

import React, {useEffect} from 'react';

const AccountTeamspirit = () => {
    useEffect(() => {
        console.log('useEffect');
        setTimeout(function () {
            console.log('Set timeout');
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1>Teamspirit</h1>
            <div className="img-with-text">
                <div className="img-container">
                    <img alt="Teamspirit" src={require("../../../assets/images/teamspirit-001.png")}/>
                </div>
                <div className="description-text">
                    If you are an Intern or a partner, you will most likely not have access to Teamspirit.<br/>
                    Teamspirit is used to track your hours for the month. You will get detailed information about how to use Teamspirit in a session with HR.
                </div>
            </div>
            <ol>
                <li>Click on the <strong>Teamspirit icon</strong> to log in.</li>
                <li>Confirm you can view the overview page:<br/>
                    <img alt="Teamspirit" src={require("../../../assets/images/teamspirit-002.png")}/>
                </li>
                <li>Please return to the HENNGE One portal.</li>
            </ol>
        </div>
    );
};

export default AccountTeamspirit;
