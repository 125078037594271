// Step-by-step AccountOffice.jsx

import React, {useEffect} from 'react';

const AccountOffice = () => {
    useEffect(() => {
        console.log('useEffect');
        setTimeout(function () {
            console.log('Set timeout');
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1>Microsoft 365</h1>
            <div className="img-with-text">
                <div className="img-container">
                    <img alt="Office 365" src={require("../../../assets/images/office-001.png")}/>
                </div>
                <div className="description-text">
                    Your access to Microsoft 365 may be different depending on your Division / Section.<br/>
                    Microsoft 365 will give you access to Microsoft Office applications such as Word, Excel, and so on online.

                </div>
            </div>
            <div className="description-text side-margin-buffer">
                If you need to install applications locally on your computer, you may do so from choosing the Install Office link.<br/>
                <img alt="Office 365" src={require("../../../assets/images/office-002.png")}/> <br/>
                You are not required to install Office on your computer, it will depend on your work.
                You may also choose to use G Suite applications (Google Docs, Google Sheets, and so on).
                <br/><br/>
                Please move to the next step.
            </div>
        </div>
    );
};

export default AccountOffice;
