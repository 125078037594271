// Step-by-step HowToUse.jsx

import React, {useEffect} from 'react';
import '../StepByStep/StepByStep.css';

const HowToUse = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
            <div className="window-content">
                <h1>How to Use This Guide</h1>
                <div className="img-with-text">
                    <div className="description-text margin-buffer">
                        This site will help you:
                        <ul>
                            <li>Understand our <strong>security policies</strong></li>
                            <li>Set up your <strong>work computer</strong> for the first time</li>
                            <li>Set up your <strong>work accounts</strong> for a number of cloud services</li>
                        </ul>
                    </div>
                        <img alt="Woman on computer" src={require("../../assets/images/kaisya_woman.png")}/>
                </div>
                <div className="margin-buffer">
                    <h2>IMPORTANT</h2>
                    Please <strong>do not skip any steps</strong> when following this guide unless told to do so.<br/><br/>
                    If you do not understand, or have any trouble during this guidance session, <strong>please ask the member in charge of the session for help</strong>.
                    We are here to support you! You don't need to struggle by yourself 😁 <br/><br/>
                    We know there is a lot of new information, so take your time, and follow the instructions on each page.<br/>
                </div>
            </div>
    );
};

export default HowToUse;
