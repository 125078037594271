// StepGreetings.jsx

import { Box, Button, Typography } from '@mui/material';
import { Auth, Hub } from 'aws-amplify';
import { Greetings, Nav, NavBar } from 'aws-amplify-react';
import React from 'react';
import UserContext from '../../UserContext';
import GuidanceHeader from './GuidanceHeader';
import './StepByStep.css';

export default class StepGreetings extends Greetings {
    static contextType = UserContext;

    parseName = () => {
        const username = this.context.username;

        if (username.includes('HENNGE')) {
            // SAML login
            const name = username
                .slice(username.indexOf('_') + 1, username.indexOf('@'))
                .split('.');

            return `${name[0][0].toUpperCase() + name[0].slice(1)} ${
                name[1][0].toUpperCase() + name[1].slice(1)
            }`;
        } else {
            // Cognito login
            const name = username.split('.');
            return `${name[0][0].toUpperCase() + name[0].slice(1)} ${
                name[1][0].toUpperCase() + name[1].slice(1)
            }`;
        }
    };

    render() {
        const theme = this.props.theme;

        const handleSignout = async () => {
            await Auth.signOut();
            Hub.dispatch('auth', { payload: { event: 'signOut' } });
            window.location.reload();
        };

        return (
            <NavBar theme={theme}>
                <Nav theme={theme}>
                    <div className='greetings-left'></div>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100vw',
                            flexDirection: { xs: 'column', md: 'row' },
                        }}
                    >
                        <GuidanceHeader />
                        <Box
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            sx={{
                                width: { xs: '100vw', md: '20rem' },
                                marginRight: { xs: 0, md: 3 },
                            }}
                        >
                            <Typography variant='h6'>{`Hello ${this.parseName()}`}</Typography>
                            <Button
                                color='warning'
                                size='large'
                                variant='contained'
                                onClick={handleSignout}
                                sx={{ marginLeft: 1.5 }}
                            >
                                Sign Out
                            </Button>
                        </Box>
                    </Box>
                </Nav>
            </NavBar>
        );
    }
}
