// PcSetupIntro.jsx

import React, {useContext, useEffect} from 'react';
import StepContext from "../StepByStep/StepContext";
import { handleOnClick } from "../StepByStep/Utils";

const PcSetupIntro = () => {
    const [, dispatch] = useContext(StepContext);
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);

    return (
        <div className="window-content">
            <h1>PC Setup</h1>
            <div className="descriptxion-text">First, let's set up your computer!</div>
            <div className="choose-pc">Please select your PC type from the choices below to continue:</div>
            <div className="all-pc-choices">
                <div className="route-choices">
                    <a href="/#" onClick={e => {
                        handleOnClick(e, dispatch, 'pc-vaio-2', '');
                    }}><div className="route-choice windows vaio">Windows </div></a>
                    <a href="/#" onClick={e => {
                        handleOnClick(e, dispatch, 'mac-jamf', '');
                    }}><div className="route-choice mac">Mac</div></a>
                    <a href="/#" onClick={e => {
                        handleOnClick(e, dispatch, 'account-setup', ' is selecting a PC Setup option.');
                    }}><div className="route-choice chromebook">Chromebook</div></a>
                </div>
                <div className="route-choices">
                    <div className="special-note">*Partners who use a PC owned by your company,
                        please click the button below to skip this procedure. Instead, you may need to install a device certificate.
                        <strong> Please ask IIT or your supervisor for the certificate. </strong></div>
                    <a href="/#" onClick={e => {
                        handleOnClick(e, dispatch, 'account-setup', ' is selecting a PC Setup option.');
                    }}><div className="route-choice partner">I am a Partner and I will use my company's PC</div></a>
                </div>
            </div>
        </div>
);}

export default PcSetupIntro;
