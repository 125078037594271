// Step-by-step AccountKeeper.jsx

import React, {useEffect} from 'react';

const AccountKeeper = () => {
    useEffect(() => {
        console.log('useEffect');
        setTimeout(function () {
            console.log('Set timeout');
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
            <div className="window-content">
                <h1>Keeper</h1>
                <div className="img-with-text">
                    <div className="img-container">
                        <img alt="Keeper" src={require("../../../assets/images/keeper-001.png")}/>
                    </div>
                    <div className="description-text">
                        Keeper is a password manager where you can securely manage your accounts’ passwords, and also where you can manage shared accounts’ login credentials.<br/><br/>

                        If you have any shared accounts within your team such as a test account, system account etc., please use this tool to share the login information.<br/><br/>

                        For the details, please refer to the <a href={"https://docs.google.com/document/d/1ca__5Rkx28JXmV3ZGZSSf_tU9u52uu4vA5roRJQwnbY/edit?usp=sharing"}>Keeper User Manual</a>, which can be found in HENNGE Documents -> Internal IT Related page.
                    </div>
                </div>
                <ol>
                    <li>Please click on the <strong>Keeper icon</strong> to confirm you can view the page<br/>
                        <img alt="Keeper" src={require("../../../assets/images/keeper-002.png")}/>
                    </li>
                    <li>Please return to the HENNGE One portal.</li>
                </ol>
            </div>
    );
};

export default AccountKeeper;
