// Step-by-step GuidanceComplete.jsx

import React, {useEffect} from 'react';

const GuidanceComplete = () => {
    useEffect(() => {
        console.log('useEffect');
        setTimeout(function () {
            console.log('Set timeout');
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1>Thank You!</h1>
            <div className="description-text margin-buffer">
                Thank you for completing Welcome Guidance!
                </div>
            <div className="description-text margin-buffer">
                An Internal IT member will reach out to your manager and let them know you are ready for the next item on your schedule.
            </div>
            <div className="description-text margin-buffer">
                If you still have any questions about anything you did during this onboarding, or ever want to review this information, you can log into this site from the HENNGE One portal at any time!
            </div>
        </div>
    );
};

export default GuidanceComplete;
