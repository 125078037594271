// ScrollToTopOnMount.jex

import React from 'react';

/* const ScrollToTopOnMount = () => {
    console.log('Scroll on mount');
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return null;
}; */

class ScrollToTopOnMount extends React.Component {
    componentDidMount() {
        console.log('ScrollToTopOnMount');
        document.querySelector('body').scrollTo(0,0);
    }

    render () {
        return null;
    }
}

export default ScrollToTopOnMount;
