// Step-by-step OtpEmail.jsx

import React, {useEffect} from 'react';
import '../StepByStep/StepByStep.css';

const OtpEmail = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1>OTP Email Setup</h1>
            <div className="description-text">
                When using OTP by Email setting, please enter your <b>personal email address</b> - NOT your hennge.com email address!
                It is important that you be able to access this email without being logged in to any HENNGE services.
            </div>
            <ol>
                <li>Click on <strong>Configure OTP by email</strong></li>
                <li>In the <strong>New Address</strong> field, enter your personal email address, which you can check on your smartphone</li>
                <li>Click <strong>Submit</strong></li>
                <li>On the following page, please enter the numbers you received to your email</li>
                <li>Click <strong>Submit</strong></li>
                <li>You should be returned to the OTP Settings page, and should see <strong>Current OTP Method: Email</strong></li>
                <li>Please move to the next section</li>
            </ol>
        </div>
    );
};
export default OtpEmail;
