// PcVaioIntune7.jsx

import React, {useEffect} from 'react';

const PcVaioIntune7 = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
            <div className="window-content">
                <h1><span>Windows&nbsp;</span><span>- VAIO - Windows Hello (6 of 7)</span></h1>
                <div id="vaio" className="guidance-sub-sub-block">
                    <ol start="7">
                        <li>On the prompt, click <strong>Get Started</strong> then input your <strong>PIN</strong></li>
                        <li>Touch the fingerprint sensor by repeatedly lifting your finger and placing it back onto
                            the sensor until setup is done. <br/>
                            Click <strong>Next</strong> when asked to try a different angle.<br/>
                        </li>
                        <li>You’re all set! You can add another finger anytime if needed.<br/>
                            <strong>*Note: Where is the fingerprint scanner?</strong> <br/>
                            Please use the power button to scan your fingerprint by <strong>lightly</strong> placing your finger on the button.<br/>
                            <strong>Do not press down on the button</strong> or your computer may turn off.<br/>
                            <img alt="Microsoft Security Confirmation" src={require("../../assets/images/new-vaio-fingerprint-scanner.png")}/>
                        </li>
                    </ol>
                </div>
            </div>
    );
};

export default PcVaioIntune7;
