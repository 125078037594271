// Step-by-step SecurityRules.jsx

import React, {useEffect} from 'react';
import '../StepByStep/StepByStep.css';

const SecurityRules4 = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1><span>Security Rules&nbsp;</span><span>(4 of 4)</span></h1>
            <div className="guidance-sub-block">
                <h2>Mobile Phones</h2>
                <ul>
                    <li>If you want to use a personally owned device (BYOD) to access privileged company information, you must use HENNGE Secure Browser to access these services (e.g. Gmail, Calendar, Google Drive, etc)</li>
                    <li>If it is absolutely necessary for your work to use native apps like Gmail on your personal device, <strong>Google Apps Device Policy</strong> must be installed on your device. This will allow us to take control of your phone and delete all of its contents in the event of loss or theft.</li>
                    <li>This will only be approved after hearing details about your situation, so please try using HENNGE Secure Browser for a while first.</li>
                    <li>
                        <span className="italics">The following native apps can be installed without Google Apps Device Policy. </span>
                        For the details, please refer HENNGE Documents site after finishing your setup.
                        <div className="flex">
                            <div className="list-block">
                                <ul>
                                    <li>Slack</li>
                                    <li>Salesforce 1</li>
                                    <li>Dialpad</li>
                                </ul>
                            </div>
                            <div className="list-block">
                                <ul>
                                    <li>Sansan</li>
                                    <li>TOKIUM</li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li><strong>If this is your first day, please proceed to the next step and do not install Secure Browser now unless you have been told to do so.</strong> <br/>
                        Instructions on how to set up Secure Browser can be found in the following sites - you must first set up your primary work device before setting up Secure Browser<br/>
                        <div className="flex">
                            <div className="list-block">
                                <ul>
                                    <li><a href="https://support.hdeone.com/hc/en-us/articles/360020644933-HENNGE-Secure-Browser-Installation-Android-">for Android</a></li>
                                </ul>
                            </div>
                            <div className="list-block">
                                <ul>
                                    <li><a href="https://support.hdeone.com/hc/en-us/articles/360020552993-HENNGE-Secure-Browser-Installation-IOS-">for iOS</a></li>
                                </ul>
                            </div>
                        </div>
                    </li>

                </ul>
            </div>
        </div>
    );
};

export default SecurityRules4;
