// Step-by-step AccessControl.jsx

import React, {useEffect} from 'react';
import ScrollToTopOnMount from "../StepByStep/ScrollToTopOnMount";

const AccessControl = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <ScrollToTopOnMount/>
            <h1><span>HENNGE Access&nbsp;</span><span>Control (SSO) Setup&nbsp;</span><span>(1 of 6)</span></h1>
            <div className="description-text">
                <div className="vertical-padding">HENNGE Access Control is the Single-Sign On (SSO) service and is part of our product, HENNGE One.
                Almost all the services we use for business can be logged in through SSO.</div>
                <div className="vertical-padding">HENNGE Access Control has a portal site that you will use for most applications. </div>
                <div className="vertical-padding">We call this portal site <strong>HENNGE One Portal</strong>.
                    We'll set this up by opening Chrome on your computer - the homepage should already be this link:</div>
                <div className="important-link"><a href="https://ap.ssso.hdems.com/portal/hennge.com/"><span>https://ap.ssso.hdems.com/</span><span>portal/</span><span>hennge.com/</span></a> </div>
                <div className="special-note center">(the slash at the end is important, please do not forget if you must type it in!)</div>
            </div>
            <ol>
                <li>Open <strong>Chrome browser</strong> and press the <strong>home button</strong> (other browsers may not have HENNGE Access Control set as the homepage)</li>
                <li>On your work machine, you should always select <strong>Device Certificate</strong> to log in to HENNGE One Portal.</li>
                <li>
                    A window should pop up asking you to <strong>select the certificate</strong> - choose the one that is listed and hit <strong>OK</strong>.
                    <ul>
                        <li>If you are using a Mac, you will be asked to enter a password - please enter your computer password.</li>
                    </ul>
                </li>
                <li>You will be asked to enter a <strong>TOTP token</strong>. Please input the temporary OTP provided to you by the person
                    in charge of this training. </li>
                <li>On your first login you will be asked to change your password.</li>
            </ol>
        </div>
    );
};

export default AccessControl;
