// Step-by-step ChromeSync.jsx

import React, {useEffect} from 'react';

const ChromeSync = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
        <div className="window-content">
            <h1><span>Chrome Sync&nbsp;</span></h1>
            <div className="description-text">
                We recommend enabling Chrome Sync to ensure that your bookmarks, history, and
                settings are automatically synced to your Google account.
                That way, if you need to change your devices or get a new laptop,
                you can easily get your synced information back.
                This will also sign you in automatically to Gmail, YouTube, Search, and other Google services.
            </div>
            <ol>
                <li>While using Google Chrome, in the top right, click Profile<br/>
                    <img alt="Spaces in Google Chat" src={require("../../assets/images/chromesync-001.png")}/>
                </li>
                <li>
                    Sign in to your Google Account (your <strong>firstname.lastname@hennge.com</strong> email address)
                </li>
                <li>If you would like to sync your info across all your devices, click <strong>Turn on sync</strong>, then click <strong>Turn on</strong></li>
                <li>Please continue to the next section</li>
            </ol>
        </div>
    );
};

export default ChromeSync;
