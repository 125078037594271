// StepByStep.jsx

import React from 'react';
import GuidanceWindow from './GuidanceWindow';

const StepByStep = () => {
    return (
        <div className='stepbystep-container'>
            <GuidanceWindow />
        </div>
    );
};

export default StepByStep;
