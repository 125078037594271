// Step-by-step AccessControl6.jsx

import React, { useEffect } from 'react';

const AccessControl6 = () => {
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 200);
    }, []);
    return (
            <div className="window-content">
                <h1><span>HENNGE Access&nbsp;</span><span>Control (SSO)&nbsp;Setup </span><span>(6 of 6)</span></h1>
                <ol start="18">
                    <li>If the code has scanned correctly, you should see an entry for it on the main screen of the app<br/>
                        <img alt="HENNGE Lock App screen" src={require("../../assets/images/hac-007.png")}/>
                    </li>
                    <li>Access Control should again show the <strong>OTP (One-Time Password) settings</strong> page</li>
                    <li>Please click on the <strong>HENNGE One logo</strong> in the top left-hand side of the page<br/>
                        <img alt="HENNGE One Logo" src={require("../../assets/images/hennge-one-portal-logo.png")}/>
                    </li>
                    <li>Please continue to the next step</li>
                </ol>
            </div>
    );
};

export default AccessControl6;
